import React from 'react';
import BlackboxPosition from '@catalogo/core-blackbox/position';
import { supportColors, fontAndSpaces } from '@catalogo/ui-americanas-mobile-theme-provider/src/theme-props';
import { ProductGridCarousel } from '@catalogo/ui-americanas-desktop-product-grid-carousel';
import { TProps } from './types';
import * as Styled from './styles';

export const ContentCarousels: React.FC<TProps> = ({ history, publications, products }) => {
  const seeMoreLink = `${history?.location?.pathname}/produtos${history?.location?.search}`;
  const recsPositions = ['store_page.rr1', 'store_page.rr2', 'store_page.rr3'];

  return (
    <>
      <Styled.GridCarouselWrapper>
        <ProductGridCarousel title="todos os produtos da loja" seeMoreLink={seeMoreLink} products={products} />
      </Styled.GridCarouselWrapper>
      {recsPositions?.map(position => (
        <BlackboxPosition
          key={position}
          position={position}
          publication={publications?.[position]}
          titleStyles={{ color: supportColors?.dark40, fontSize: fontAndSpaces?.fontSize?.lg }}
        />
      ))}
    </>
  );
};
