import React from 'react';
import styled from 'styled-components';
import CardImage from '../atoms/card-image';

const ImageInfo = cardProps => (
  <Wrapper>
    <CardImage {...cardProps} />
  </Wrapper>
);

export default ImageInfo;

const Wrapper = styled.div``;
