import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import Phone from '@catalogo/ui-americanas-desktop-svg/phone.svg';
import Mail from '@catalogo/ui-americanas-desktop-svg/mail.svg';
import Question from '@catalogo/ui-americanas-desktop-svg/questionmark.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  color: ${({ theme }) => theme.grey.darker};
  max-width: 1000px;
  margin: 0 auto;
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

export const Term = styled.span`
  display: block;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 32px;
`;

export const Subtitle = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.title};
  text-align: center;
  margin-bottom: 16px;
`;

export const List = styled.ul``;

export const ListItem = styled.li`
  font-size: ${({ theme }) => theme.fontSize.sm};
  line-height: 22px;
  list-style-type: disc;
`;

export const Contact = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  border-radius: 8px;
  width: 120%;
  padding: 24px;
`;

export const Content = styled.div``;

export const Title = styled.strong`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.title};
  font-size: ${({ theme }) => theme.fontSize.lg};
  line-height: ${({ theme }) => theme.fontSize.xl};
  margin-bottom: 4px;
`;

export const ContactSubtitle = styled.strong`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.sm};
  line-height: ${({ theme }) => theme.fontSize.lg};
  margin-bottom: 4px;
  position: relative;
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  line-height: ${({ theme }) => theme.fontSize.md};
`;

export const SmallText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  line-height: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.textColor};
`;

export const Info = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  line-height: ${({ theme }) => theme.fontSize.md};
  margin: 20px 0 16px;
  position: relative;
`;

export const Link = styled(Nav)`
  font-weight: ${({ theme }) => theme.fontWeight.title};
  color: ${({ theme }) => theme.primaryColor};
`;

export const PhoneUI = styled(Phone)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -32px;
`;

export const MailUI = styled(Mail)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -32px;
`;

export const QuestionUI = styled(Question)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -32px;
`;
