import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { getPlacements, mergeComponents } from '@catalogo/service-americanas-common/src/helpers/template';
import { formatFiltersFromQS } from '@catalogo/core-filters';
import { getIn } from 'seamless-immutable';
import productCardQuerySearchDesktopAcom from '@catalogo/service-americanas-search/src/queries/product-card-search.graphql';
import { setHeaderContext } from '@catalogo/service-americanas-header/src/context/header';
import { DocumentNode } from 'graphql';
import { pageSearch } from '../queries/search.graphql';
import { getDeferedSearch } from '../queries/defer.graphql';
import { mountNewSearch } from '../helpers/helpers';
import {
  AdsType,
  DispatchMetricsType,
  UseGetDataSearchProps,
  UseGetSearchDeferedDataProps,
  UseReloadSearchNewCEPProps,
  VariablesType,
} from '../types';

/* istanbul ignore next */
export const useServiceSearch = (
  variables: VariablesType,
  dispatchMetrics: DispatchMetricsType,
  query = pageSearch
) => {
  let skip = variables.content === '';
  const { loading, data, called, error, networkStatus, fetchMore, refetch } = useQuery(query, {
    variables,
    skip,
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      skip = called;
      dispatchMetrics('page:load', data);
    },
  });

  const template = getIn(data, ['page', 'template']) || {};
  const recPlacements = getPlacements('recommendation', template);
  const b2wAdsPlacements = getPlacements('b2wads', template);
  const categoryId = getIn(data, ['page', 'breadcrumb', 0, 'id']);

  const { data: dataDefered } = useQuery(getDeferedSearch, {
    variables: { categoryId, recPlacements, b2wAdsPlacements, term: variables.content, pageType: 'search' },
    skip: recPlacements.length < 1 && b2wAdsPlacements.length < 1,
    ssr: false,
  });

  return { loading, data, called, error, networkStatus, fetchMore, refetch, dataDefered };
};

export const useGetDataSearch = ({
  term,
  content,
  context,
  source,
  sortBy,
  routeId,
  offset,
  limit,
  suggestion,
  oneDayDelivery,
  dispatchMetrics,
  sortByOffer,
  parsedQs,
}: UseGetDataSearchProps) => {
  const { loading, data = {}, refetch, error, previousData = {}, fetchMore, networkStatus } = useQuery(pageSearch, {
    variables: {
      path: `/busca/${term}${mountNewSearch(parsedQs, ['viewMode'])}`,
      content,
      context,
      source,
      sortBy,
      sortByOffer,
      routeId,
      offset: parseInt(offset),
      limit: parseInt(limit),
      suggestion,
      segmented: false,
      filters: formatFiltersFromQS(mountNewSearch(parsedQs, ['viewMode'])),
      oneDayDelivery,
    },
    skip: !content,
    onCompleted: data => {
      dispatchMetrics('page:load', data);
    },
  });

  const searchData = loading ? previousData : data;
  return { loading, searchData, refetch, error, fetchMore, networkStatus };
};

export const useGetSearchDeferedData = ({
  searchData,
  SearchId,
  template,
  content,
  dispatchMetrics,
}: UseGetSearchDeferedDataProps) => {
  const hasSearchData = searchData?.search?.products?.length > 0;
  const recPlacementsNoResult = ['search_page.rr1_noresults', 'search_page.rr2_noresults', 'search.2'];
  const recPlacements = hasSearchData ? getPlacements('recommendation', template) : recPlacementsNoResult;
  const b2wAdsPlacements = getPlacements('b2wads', template);
  const newtailPlacements = getPlacements('newtail', template);

  const { data: recommendationData } = useQuery(getDeferedSearch, {
    variables: {
      SearchId,
      recPlacements,
      b2wAdsPlacements,
      newtailPlacements,
      term: content,
      pageType: 'search',
      enableAds: true,
    },
    skip: recPlacements.length < 1 && b2wAdsPlacements.length < 1 && newtailPlacements.length < 1,
    ssr: false,
    onCompleted: data => {
      dispatchMetrics('recommendation:load', mergeComponents(searchData, data));
      dispatchMetrics('ads:load', mergeComponents(searchData, data));
    },
  });
  return { recommendationData };
};

export const useGetProductAd = (
  ads: AdsType,
  publications: Record<string, string>,
  position: string,
  pageType: string
) => {
  const productId = ads?.[0]?.id;
  const sellerId = ads?.[0]?.sellerId;
  const trackingUrl = ads?.[0]?.trackingUrl;
  const queryString = ads?.[0]?.queryString;
  const publication = publications?.[position];

  const { data, loading, called } = useQuery(productCardQuerySearchDesktopAcom, {
    variables: {
      productId,
      sellerId,
    },
    skip: !productId,
  });

  if (!data) {
    return { productAd: null, loading, called };
  }

  const productAd = {
    ...data,
    product: {
      ...data?.product,
      trackingUrl,
      queryString,
      pfm: {
        publication,
        position,
        positionCard: 0,
        pageType,
      },
      offers: {
        ...data?.product?.offers,
        result: [
          {
            ...data?.product?.offers?.result[0],
            stamps: {
              ...data?.product?.offers?.result[0]?.stamps,
              ad: true,
            },
          },
        ],
      },
    },
  };

  return { productAd, loading, called };
};

export const useHeaderInputAmericanasMundo = (content: string, context: string) => {
  useEffect(() => {
    if (context && context === 'americanas_mundo') {
      setHeaderContext({
        name: 'Americanas Mundo',
        placeholder: 'tem tuuudo, do mundo todo, pode procurar :)',
        context: 'americanas_mundo',
      });
    }
  }, []);
};

export const useReloadSearchNewCEP = ({ cookies, term, history }: UseReloadSearchNewCEPProps) => {
  const address = getIn(cookies, ['persistentAddress']);
  const [oldAddress, setOldAddress] = useState(address);
  useEffect(() => {
    if (oldAddress !== address) {
      try {
        const search = history?.location?.search;
        window.location.href = `/busca/${term}${search}`;
        setOldAddress(address);
      } catch (err) {
        return;
      }
    }
  }, [address]);
  return { oldAddress, address };
};

export const useServiceFilter = (
  variables: Record<string, string>,
  dispatchMetrics: DispatchMetricsType,
  query: DocumentNode
) => {
  const { loading, data, refetch } = useQuery(query, {
    variables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      dispatchMetrics('page:load', data);
    },
  });

  return { loading, data, refetch };
};
