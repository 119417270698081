import React from 'react';
import PersonIcon from '@catalogo/ui-americanas-desktop-svg/person.svg';
import { ISellerImageProps } from './types';
import * as Styled from './styles';

export const SellerImage = ({ sellerImage, sellerName }: ISellerImageProps) => (
  <Styled.Wrapper>
    {sellerImage ? <Styled.SellerImg src={sellerImage} alt={sellerName} /> : <PersonIcon />}
  </Styled.Wrapper>
);
