import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { getIn } from 'seamless-immutable';
import { getPlacements } from '@catalogo/service-americanas-category/src/helpers/template';
import { addParamRandom } from '@catalogo/ui-americanas-desktop-header/src/components/top/login/components/helper';
import { searchCategory } from '../queries/category-search.graphql';
import { getDeferedCategory } from '../queries/defered.graphql';
import {
  IUseGetCategoryDataProps,
  IUseGetCategoryDeferedDataProps,
  IUseReloadCategoryNewCEPProps,
} from '../types/hooks/category';

export const useGetCategoryData = ({
  dispatchMetrics,
  parsedQs,
  pathname,
  routeId,
  sitepage,
}: IUseGetCategoryDataProps) => {
  const { sortBy = 'relevance', source = 'blanca', limit = 24, offset = 0 } = parsedQs;
  const variables = {
    path: pathname,
    sortBy,
    source,
    limit: typeof limit === 'number' ? limit : parseInt(limit),
    offset: typeof offset === 'number' ? offset : parseInt(offset),
    routeId,
    sitepage,
  };

  const { loading, data = {}, refetch, error, previousData = {}, fetchMore, networkStatus } = useQuery(searchCategory, {
    variables,
    notifyOnNetworkStatusChange: true,
    onCompleted: /* istanbul ignore next */ data => {
      dispatchMetrics('page:load', data);
    },
  });
  const categoryData = loading ? previousData : data;

  return { loading, categoryData, refetch, error, fetchMore, networkStatus };
};

export const useGetCategoryDeferedData = (props: IUseGetCategoryDeferedDataProps) => {
  const { dispatchMetrics, mergeComponents, page = {} } = props;

  const template = getIn(page, ['template'], {});

  const recPlacements = getPlacements('recommendation', template);
  const b2wAdsPlacements = getPlacements('b2wads', template);
  const newtailPlacements = getPlacements('newtail', template);
  const getBreadcrumb = getIn(page, ['breadcrumb'], []);
  const getLastCategory = getBreadcrumb.length - 1;
  const categoryId = getIn(page, ['breadcrumb', getLastCategory, 'id']);
  const categoryName = getIn(page, ['breadcrumb', getLastCategory, 'name']);

  const { name } = page;

  const variables = {
    categoryId,
    categoryName,
    recPlacements,
    b2wAdsPlacements,
    newtailPlacements,
    term: name ? name.toLowerCase() : '',
    pageType: 'category',
  };

  const { data: dataDefered } = useQuery(getDeferedCategory, {
    variables,
    skip: recPlacements.length < 1 && b2wAdsPlacements.length < 1 && newtailPlacements.length < 1,
    ssr: false,
    onCompleted: /* istanbul ignore next */ data => {
      dispatchMetrics('recommendation:load', mergeComponents(page, data));
    },
  });

  return dataDefered;
};

export const useReloadCategoryNewCEP = ({ cookies, history }: IUseReloadCategoryNewCEPProps) => {
  const address = getIn(cookies, ['persistentAddress']);
  const [oldAddress, setOldAddress] = useState(address);

  useEffect(() => {
    if (oldAddress !== address) {
      if (typeof window !== 'undefined') {
        const { search, pathname } = history?.location;
        window.location.href = addParamRandom(pathname, search);
        setOldAddress(address);
      }
    }
  }, [address]);

  return { oldAddress, address };
};
