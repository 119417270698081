import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import PriceInfo from '../molecules/price-info';
import ImageInfo from '../molecules/image-info';
import ProductInfo from '../molecules/product-info';

const InStockCardList = ({ url, handleClick, last = false, ...cardProps }) => (
  <Wrapper to={url} onClick={handleClick} last={last}>
    <ContainerTop>
      <ImageWrapper>
        <ImageInfo {...cardProps} />
      </ImageWrapper>
      <ContainerInfo>
        <ProductInfo {...cardProps} />
        <PriceInfo {...cardProps} />
      </ContainerInfo>
    </ContainerTop>
  </Wrapper>
);
export default InStockCardList;

const Wrapper = styled(Nav)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 16px 0;
`;

const ContainerTop = styled.div`
  display: flex;
  position: relative;
  padding: 16px 32px;
  border-left: 1px solid #e8e8e8;
`;

const ImageWrapper = styled.div`
  margin-right: 16px;
`;

const ContainerInfo = styled.div`
  position: relative;
  padding: 7px 0;
`;
