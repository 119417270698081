import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Informations = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const Circle = styled.div`
  background: ${({ theme }) => theme.grey.dark};
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin: 0 10px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.typography.family};
    color: ${theme.supportColors.dark50};
    font-size: ${theme.fontSize.xl};
    font-weight: ${theme.typography.fontWeight.bold};
  `}
`;

export const Address = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

export const Reputation = styled.p`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.typography.fontWeight.bold};
    color: ${theme.supportColors.dark30};
    margin-top: 2px;

    svg {
      margin-right: 5px;
      width: 20px;
    }
  `}
`;
