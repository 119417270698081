import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 30px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
  border-left: 6px solid ${({ theme }) => theme.primaryColor};
`;

export const SellerContent = styled.div`
  display: flex;
  padding: 30px;
`;

export const SellerInfoContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 15px;
`;
