import React from 'react';
import { getIn } from 'seamless-immutable';
import compose from 'lodash/fp/compose';

import { withMetrics } from '@catalogo/core-metrics';
import { withCookies } from '@catalogo/core-connect/cookies';
import { withNavigation } from '@catalogo/core-connect/navigation';
import { mergeComponents } from '@catalogo/service-americanas-brand/src/helpers/template';

import { useGetBrandData, useGetBrandDeferedData } from '../hooks/brand';
import { WithBrandProps } from '../types';

const verifyBrand = (brand: string) =>
  !['eletroportáteis', 'celulares e smartphones', 'informática', 'tv e home theater', 'eletrodomésticos'].find(
    str => !!str.includes(brand.toLowerCase())
  );

export const withBrand = (WrappedComponent: React.FC) => {
  const EnhancedBrand = ({
    history,
    location,
    routeId,
    parsedQs = {},
    match,
    dispatchMetrics,
    sortByList,
    ...props
  }: WithBrandProps) => {
    const pathname = getIn(location, ['pathname'], '');
    const sitepage = getIn(parsedQs, ['sitepage']) ? `/${getIn(parsedQs, ['sitepage'])}` : undefined;
    const term = getIn(match, ['params', 'term'], '');

    const { loading, brandData, refetch, error, fetchMore, networkStatus } = useGetBrandData({
      pathname,
      parsedQs,
      qs: location.search,
      dispatchMetrics,
      routeId,
      sitepage,
    });

    const dataDefered = useGetBrandDeferedData({
      location,
      match,
      mergeComponents,
      dispatchMetrics,
      page: { ...getIn(brandData, ['page'], {}) },
    });

    const allData = dataDefered ? mergeComponents(brandData, dataDefered) : brandData;

    const products = getIn(allData, ['search', 'products'], []);

    const type = products ? 'brand' : null;

    const publications = getIn(allData, ['page', 'components'], {});

    const brand = getIn(brandData, ['page', 'breadcrumb', 0, 'name'], '');

    const componentProps = {
      data: allData,
      error: error,
      products: products,
      history: history,
      loading: loading,
      parsedQs: parsedQs,
      refetch: refetch,
      routeId: routeId,
      term: term,
      publications: publications,
      fetchMore: fetchMore,
      type: type,
      networkStatus: networkStatus,
      dispatchMetrics: dispatchMetrics,
      sortByList: sortByList,
      isToRenderList: !verifyBrand(brand),
    };

    return <WrappedComponent {...componentProps} {...props} />;
  };

  return compose(withCookies, withMetrics, withNavigation)(EnhancedBrand);
};
