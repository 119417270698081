import React from 'react';
import { ISellerInfoProps } from './types';
import * as Styled from './styles';

export const SellerInfo = ({ name, city, state, BadgeIcon, badge }: ISellerInfoProps) => (
  <Styled.Wrapper>
    <Styled.Title>{name}</Styled.Title>
    <Styled.Informations>
      {badge && (
        <Styled.Reputation>
          {BadgeIcon && <BadgeIcon />}
          {badge?.title}
          <Styled.Circle />
        </Styled.Reputation>
      )}
      <Styled.Address>
        {city}, {state}
      </Styled.Address>
    </Styled.Informations>
  </Styled.Wrapper>
);
