import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import ImageInfo from '../molecules/image-info';
import BadgesInfo from '../molecules/badges-info';
import ProductInfoBrand from '../molecules/product-info-brand';

const CardBrandList = ({ url, handleClick, ...cardProps }) => (
  <Wrapper to={url} onClick={handleClick}>
    <ContainerTop>
      <ImageWrapper>
        <ImageInfo {...cardProps} />
      </ImageWrapper>
      <ContainerInfo>
        <BadgesInfo {...cardProps} />
        <ProductInfoBrand {...cardProps} />
      </ContainerInfo>
    </ContainerTop>
  </Wrapper>
);
export default CardBrandList;

const Wrapper = styled(Nav)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 32px;
  align-items: center;
  justify-content: center;
`;

const ContainerTop = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.div`
  margin-right: 16px;
`;

const ContainerInfo = styled.div``;
