import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import PriceInfo from '../molecules/price-info';
import ImageInfo from '../molecules/image-info';
import ProductInfo from '../molecules/product-info';

const InStockSingleCard = ({ url, handleClick, last = false, isSecondaryLayout, ...cardProps }) => (
  <Wrapper to={url} onClick={handleClick} last={last}>
    <ContainerTop>
      <ImageInfo {...cardProps} />
      <ContainerInfo>
        <StyledProductInfo isSecondaryLayout={isSecondaryLayout} {...cardProps} />
        <PriceInfo {...cardProps} />
      </ContainerInfo>
    </ContainerTop>
  </Wrapper>
);
export default InStockSingleCard;

const Wrapper = styled(Nav)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 16px 0;
`;

const ContainerTop = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 16px 42px;
  width: 100%;
`;

const ContainerInfo = styled.div`
  position: relative;
  padding: 20px 0;
  display: flex;
`;

const StyledProductInfo = styled(ProductInfo)`
  width: 520px;
`;
