/* eslint-disable max-lines-per-function */
/* eslint max-statements: [2, 20] */
import React from 'react';
import compose from 'lodash/fp/compose';
import { Helmet } from 'react-helmet';
import { getIn } from 'seamless-immutable';
import { withMetrics } from '@catalogo/core-metrics';
import { withCookies } from '@catalogo/core-connect/cookies';
import { mergeComponents } from '@catalogo/service-americanas-common/src/helpers/template';
import { withNavigation } from '@catalogo/core-connect/navigation';
import { withDevice } from '@catalogo/core-connect/device';

import {
  useGetDataSearch,
  useGetSearchDeferedData,
  useHeaderInputAmericanasMundo,
  useReloadSearchNewCEP,
} from '../hooks/search';
import { WithSearchProps } from '../types';

export const withSearch = (WrappedComponent: React.FC) => {
  const EnhancedSearch = ({
    history,
    location,
    routeId,
    parsedQs = {},
    match,
    dispatchMetrics,
    cookies,
    sortByOffer = '',
    sortByList,
    ...props
  }: WithSearchProps) => {
    const { sortBy, offset = 0, limit = 0, source, suggestion, rc, context } = parsedQs as any;
    const term = getIn(match, ['params', 'term'], '');
    const oneDayDelivery = getIn(cookies, ['oneDayDelivery']) === 'true';
    const content = rc || term.replace(/-/g, ' ');
    useHeaderInputAmericanasMundo(content, (context as unknown) as string);

    const { loading, searchData = {}, refetch, error, fetchMore, networkStatus } = useGetDataSearch({
      term,
      content,
      context,
      source,
      sortBy,
      sortByOffer,
      routeId,
      offset,
      limit,
      suggestion,
      location,
      oneDayDelivery,
      dispatchMetrics,
      parsedQs,
    });

    const template = getIn(searchData, ['page', 'template']) || {};
    const searchId = getIn(searchData, ['page', 'breadcrumb', 0, 'id']);

    const { recommendationData = {} } = useGetSearchDeferedData({
      searchData,
      SearchId: searchId,
      template,
      content,
      dispatchMetrics,
    });
    const allData = recommendationData ? mergeComponents(searchData, recommendationData) : searchData;
    const publications = getIn(allData, ['page', 'components'], {});

    const noIndex = searchData?.search?.content?.used === false;

    const productOrigin = allData?.search?.aggregations?.find(
      ({ aggregationId }: { aggregationId: string }) => aggregationId === 'origin'
    );
    const isMarketEnabled = productOrigin?.options?.some(
      ({ params }: { params: { value: string } }) => params?.value === 'mercado'
    );

    useReloadSearchNewCEP({
      cookies,
      term,
      history,
    });

    const componentProps = {
      data: allData,
      error,
      history,
      loading,
      parsedQs,
      refetch,
      routeId,
      match,
      term,
      location,
      publications,
      fetchMore,
      hasNextPage: getIn(allData, ['search', 'hasNextPage'], false),
      dispatchMetrics,
      networkStatus,
      sortByList,
      pageType: 'search',
      address: getIn(cookies, ['persistentAddress']),
      isMarketEnabled,
    };

    return (
      <>
        {noIndex && (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}
        <WrappedComponent {...componentProps} {...props} />;
      </>
    );
  };
  return compose(withDevice, withCookies, withMetrics, withNavigation)(EnhancedSearch);
};
