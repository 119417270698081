import styled from 'styled-components';

import ArrowIcon from '@catalogo/theme-svg/arrow.svg';

interface IDevice {
  isMobile: boolean;
}

interface ILink extends IDevice {
  textLowerCase?: string;
}

export const Wrapper = styled.div<IDevice & { margin?: string }>`
  --size: 13px;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '35px' : `auto`)};
  padding: 0px 5px;
  overflow: hidden;
  margin: ${({ margin }) => margin || '0px'};
`;

export const List = styled.ul<IDevice>`
  display: flex;
  overflow-x: ${({ isMobile }) => (isMobile ? 'scroll' : `hidden`)};

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ListItem = styled.li`
  font-size: var(--size);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Arrow = styled(ArrowIcon)`
  width: calc(var(--size) - 5px);
  height: calc(var(--size) - 5px);
  margin: 0px 10px;
  fill: #666;
  transform: rotate(-90deg);
`;

export const Link = styled.a<ILink>`
  cursor: pointer;
  white-space: nowrap;
  font-size: var(--size);
  text-transform: ${({ textLowerCase }) => (textLowerCase ? 'lowercase' : 'none')};
  text-decoration: ${({ isMobile }) => (isMobile ? 'underline' : `none`)};
  color: #666;

  &:hover {
    text-decoration: underline;
  }
`;
