import { stringify } from 'querystring';
import { mountFilters } from '@catalogo/service-americanas-newsletter/src/helpers';

export const getSortByOffer = sortBy => {
  if (sortBy === 'higherPrice') {
    return 'salesPrice.desc';
  }
  if (sortBy === 'lowerPrice') {
    return 'salesPrice.asc';
  }
  return '';
};

export const excludeQs = (parsedQs = {}, list = []) =>
  Object.keys(parsedQs).reduce(
    (newQs, item) => (list.includes(item) ? { ...newQs } : { ...newQs, [item]: parsedQs[item] }),
    {}
  );

export const mountNewSearch = (parsedQs = {}, list = []) => {
  const stringUrl = stringify(excludeQs(parsedQs, list));
  return stringUrl ? `?${stringUrl}` : '';
};

export const getSearchQs = (parsedQs, match) => {
  const filtersFromParsedQs = parsedQs?.filter ?? [];
  const filtersFromMatch = mountFilters(parsedQs, match?.params ?? {})?.map(filter => JSON.stringify(filter));
  const mergedFilters = [...filtersFromParsedQs, ...filtersFromMatch];
  const mountedParsedQs = { ...parsedQs, filter: [...new Set(mergedFilters)] };

  return `?${stringify(mountedParsedQs)}`;
};
