import React from 'react';
import { getIn } from 'seamless-immutable';
import { withConfig } from '@catalogo/core-connect/config';
import { getSearchSchema } from '@catalogo/template-americanas-mobile-search/src/schema/search';
import {
  fmtOrganizationSchema,
  fmtWebSiteSchema,
  fmtWebPageSchema,
  fmtSearchResultsPageSchema,
} from '@catalogo/template-americanas-mobile-search/src/schema';

const fmtBreadcrumbSchema = ({ config, page }) => {
  const { domainUrl } = config;
  const breadcrumb = getIn(page, ['breadcrumb']);
  const home = { name: 'Home', url: `${domainUrl}` };
  const navigation = breadcrumb ? [home, ...breadcrumb] : [home];

  const list = navigation.map((item, index) => {
    if (index === 0) {
      return {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@type': 'WebPage',
          '@id': item && item.url,
          name: 'Página Inicial',
        },
      };
    }
    return {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@type': 'WebPage',
        '@id': `${domainUrl}${item && item.url}`,
        name: item.name,
      },
    };
  });

  return {
    '@type': 'BreadcrumbList',
    itemListElement: list,
  };
};

export const Schema = props => {
  const graph = [
    fmtOrganizationSchema(props),
    fmtWebSiteSchema(props),
    fmtWebPageSchema(props),
    fmtSearchResultsPageSchema(props),
    fmtBreadcrumbSchema(props),
    getSearchSchema(props),
  ];

  const schema = {
    '@context': 'https://schema.org',
    '@graph': graph,
    '@type': 'Schema',
  };

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />;
};

export default withConfig(Schema);
