import React from 'react';
import { getIn } from 'seamless-immutable';
import styled from 'styled-components';
import { ToggleButton, handleChange } from '@catalogo/ui-submarino-mobile-toggle-button';

const ToggleFilter = ({ data, history, filterSlug, children }) => {
  const { aggregations } = data?.search;
  const filter = aggregations.find(agg => agg.id === filterSlug);
  const filtered = getIn(filter, ['filtered'], false);
  const url = filter?.options?.[0]?.href;

  return filter ? (
    <Container
      onKeyDown={e => {
        if (e.key === 'Enter') {
          handleChange(history, url, filtered)();
        }
      }}
    >
      <ToggleButton history={history} filtered={filter?.filtered ?? false} url={filter?.options?.[0]?.href} />
      <TextWrapper>{children}</TextWrapper>
    </Container>
  ) : null;
};

export default ToggleFilter;

const Container = styled.button`
  display: flex;
  padding: 0px 0px 15px 0px;
  align-items: center;
  background: transparent;
  border: none;
`;

const TextWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.grey.dark};
  padding-right: 30px;
  margin-left: 8px;
`;
