import React from 'react';
import styled from 'styled-components';
import productCardQuerySearchDesktopAcom from '@catalogo/service-americanas-search/src/queries/product-card-search.graphql';
import ProductBrandingBusca from '@catalogo/ui-americanas-desktop-product-branding-busca';
import { useFeatureProduct } from '@catalogo/service-americanas-product-card/src/hooks/feature-product';
import ProductAds from '@catalogo/ui-americanas-desktop-product-ads';

const ProductAdsCard = props => {
  const { publications, pageType, positionAds, positionBrand, isSecondaryLayout } = props;

  const { products, sponsorName, position, brand, publication, error } = useFeatureProduct(publications, {
    positionAds,
    positionBrand,
  });

  if (error) {
    return null;
  }

  const featureProduct = (products, Component, sponsorName) => {
    if (products.length === 1 && Component === ProductAds) {
      return (
        <ProductAds
          key={products[0].id}
          positionCard={0}
          {...products[0]}
          sponsorName={sponsorName}
          query={productCardQuerySearchDesktopAcom}
          isSingle={true}
          position={position}
          publication={publication}
          pageType={pageType}
          isSecondaryLayout={isSecondaryLayout}
        />
      );
    }

    return products
      .slice(0, 2)
      .map((product, index) => (
        <Component
          key={product.id}
          positionCard={index}
          {...product}
          sponsorName={sponsorName}
          query={productCardQuerySearchDesktopAcom}
          position={position}
          publication={publication}
          pageType={pageType}
          isSecondaryLayout={isSecondaryLayout}
        />
      ));
  };

  const renderAds = () => {
    if (brand) {
      const featured = featureProduct(products, ProductBrandingBusca, sponsorName);
      return [<ProductBrandingBusca key="featured-1" {...publication} brand />, ...featured];
    }
    return products?.length && featureProduct(products, ProductAds);
  };

  return <Wrapper $isSecondaryLayout={isSecondaryLayout}>{renderAds()}</Wrapper>;
};

const Wrapper = styled.div`
  margin: 0 auto 16px;
  display: flex;
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  height: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? '222px' : '196px')};
  width: 100%;
`;

export default ProductAdsCard;
