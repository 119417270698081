export const fmtSuggestionHref = (suggestionUrl: string, page: string) => {
  const PAGETYPES: Record<string, string> = { categoria: 'category', busca: 'search', produto: 'product' };

  if (!suggestionUrl || !PAGETYPES[page]) {
    return undefined;
  }

  const separator = suggestionUrl.includes('?') ? '&' : '?';

  return `${suggestionUrl}${separator}pfm_type=spectreSuggestions-${PAGETYPES[page]}`;
};
