import React from 'react';
import styled, { css } from 'styled-components';

const Installment = ({ installmentPhase }) => <InstallmentUI>{installmentPhase}</InstallmentUI>;

const InstallmentUI = styled.span`
  ${({ theme }) => css`
    color: ${theme.grey.dark};
    font-size: ${theme.fontSize.xxs};
    line-height: ${theme.fontSize.xxs};
    height: 12px;
  `}
`;

export default Installment;
