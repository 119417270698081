import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: 20px;
    background-color: ${theme.white};
    border-radius: 0 0 16px 16px;
  `}
`;

export const Option = styled.a`
  ${({ theme }) => css`
    flex-grow: 1;
    text-align: center;
    padding: 5px 0;
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.typography.fontWeight.bold};
    color: ${theme.primaryColor};
    cursor: pointer;

    & :first-child {
      border-right: 1px ${theme.supportColors.light60} solid;
      padding-right: 10px;
      margin-right: 10px;
    }

    & :hover {
      text-decoration: underline;
    }
  `}
`;
