import React from 'react';
import { toTitleCase } from '@catalogo/core-helpers/utils';
import { getSellerBadge } from '@catalogo/service-americanas-product/src/helpers/seller-reputation';
import * as Styled from './styles';
import { ISellerAboutProps } from './types';
import { TopSeal } from './components/top-seal';
import { SellerImage } from './components/seller-image';
import { SellerOptions } from './components/seller-options';
import { SellerInfo } from './components/seller-info';

export const SellerAbout = ({ name, image, address, description, policy, loyalty, history }: ISellerAboutProps) => {
  const sellerBadge = getSellerBadge(loyalty?.badge as string);
  const BadgeIcon = sellerBadge?.image;
  const sellerName = toTitleCase(name);
  const city = toTitleCase(address?.city);
  const state = address?.state?.toUpperCase();
  const newStore = !loyalty?.badgeSalesVolume;

  return (
    <Styled.Wrapper>
      {newStore && <TopSeal />}
      <Styled.SellerContent>
        <SellerImage sellerImage={image?.main as string} sellerName={sellerName} />
        <Styled.SellerInfoContent>
          <SellerInfo name={sellerName} city={city} state={state} BadgeIcon={BadgeIcon} badge={sellerBadge} />
          <SellerOptions name={name as string} policy={policy} description={description} history={history} />
        </Styled.SellerInfoContent>
      </Styled.SellerContent>
    </Styled.Wrapper>
  );
};
