import React from 'react';
import styled from 'styled-components';
import withProductAds from '@catalogo/service-americanas-product-card/src/hocs/product-ads';
import InStockCardList from './components/organisms/inStockCardList';
import OutOfStockCardList from './components/organisms/outOfStockCardList';
import InStockSingleCard from './components/organisms/inStockSingleCard';
import OutOfStockSingleCard from './components/organisms/outOfStockSingleCard';
import { Placeholder } from './skeleton';

export const ProductAds = props => {
  const { loading, called, outOfStock, isSingle } = props;

  if (loading || !called) {
    return <Placeholder />;
  }

  if (isSingle) {
    return (
      <Container>
        {!outOfStock && <InStockSingleCard {...props} />}
        {outOfStock && <OutOfStockSingleCard {...props} />}
      </Container>
    );
  }

  return (
    <Container>
      {!outOfStock && <InStockCardList {...props} />}
      {outOfStock && <OutOfStockCardList {...props} />}
    </Container>
  );
};

export default withProductAds(ProductAds);

const Container = styled.div`
  flex: 1 1 100%;
`;
