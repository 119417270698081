import { getIn } from 'seamless-immutable';

export const fmtAggregateRating = ({ product }) => {
  const average = getIn(product, ['rating', 'average']) || '';
  const reviews = getIn(product, ['rating', 'reviews'], 0);
  if (reviews > 0 && average >= 4) {
    const aggregateRating = {
      '@type': 'AggregateRating',
      bestRating: '5',
      ratingCount: reviews.toString(),
      ratingValue: average.toString(),
      worstRating: '1',
    };
    return { aggregateRating };
  }
};

const getPrice = offer => {
  const bestPayment = getIn(offer, ['bestPaymentOption'], {});

  if (bestPayment && bestPayment.type === 'BOLETO') {
    return bestPayment.price;
  }

  if (bestPayment && bestPayment.type === 'PIX') {
    return bestPayment.price;
  }

  return getIn(bestPayment, ['minInstallment', '0', 'value'], 0);
};
const fmtOffer = ({ product }) => {
  const totalOffers = getIn(product, ['offers', 'meta', 'total']);

  const offer = getIn(product, ['offers', 'result', 0]);
  if (offer) {
    const { currency } = offer;
    const price = getPrice(offer);

    const offers = {
      '@type': 'AggregateOffer',
      lowPrice: price && price.toString(),
      priceCurrency: currency,
      offerCount: totalOffers.toString(),
    };
    return { offers };
  }
};

export const getSearchDefault = ({ product }) => {
  const image = getIn(product, ['images', '0', 'large'], '');

  const name = getIn(product, ['name'], '');

  const schema = {
    '@type': 'Product',
    name,
    image,
  };

  return schema;
};

export const getSearchSchema = ({ search, config, location }) => {
  if (!search) {
    return null;
  }

  const firstProduct = search?.products?.[0] || [];
  const { domainUrl } = config;
  const pathname = getIn(location, ['pathname'], '');

  const list = [
    {
      ...getSearchDefault(firstProduct),
      ...fmtAggregateRating(firstProduct),
      ...fmtOffer(firstProduct),
    },
  ];

  return {
    '@id': `${pathname}/#itemList`,
    '@type': 'ItemList',
    url: `${domainUrl}${pathname}`,
    numberOfItems: list.length,
    itemListElement: list,
  };
};
