import React from 'react';
import { replaceUrlParams } from '@catalogo/service-americanas-product/src/helpers/url';
import * as Styled from './styles';
import { ISellerOptionsProps } from './types';

export const SellerOptions = ({ history, name, policy, description }: ISellerOptionsProps) => (
  <Styled.Wrapper>
    <Styled.Option onClick={replaceUrlParams(history, '#modal-about-seller', false, { name, policy, description })}>
      sobre a loja
    </Styled.Option>
    <Styled.Option onClick={replaceUrlParams(history, '#modal-seller-reputation-info', false)}>
      como funciona a avaliação
    </Styled.Option>
  </Styled.Wrapper>
);
