import React from 'react';
import styled from 'styled-components';
import SortBy from '@catalogo/ui-americanas-desktop-sort-by';
import SelectedFilterContent from '@catalogo/ui-americanas-desktop-selected-filter/src/index';
import ToggleFilter from '@catalogo/template-americanas-desktop-search/src/components/toggle-filter';
import SortByAlert from '@catalogo/ui-americanas-desktop-sort-by-alert/src';
import BlackboxPosition from '@catalogo/core-blackbox/position';
import FiltersContent from '@catalogo/ui-americanas-desktop-filters';

export const SelectedFilter = ({ data, parsedQs, history }) => {
  const selectedAggregations = data?.search?.selectedAggregations ?? [];
  const aggregations = data?.search?.aggregations ?? [];
  const cleanAll = data?.search?.cleanAll ?? '';

  return (
    <SelectedFilterContent
      parsedQs={parsedQs}
      selectedAggregations={selectedAggregations}
      history={history}
      aggregations={aggregations}
      cleanAll={cleanAll}
      bold="normal"
    />
  );
};

export const SearchTop = ({ parsedQs, history }) => (
  <>
    <SearchTopContent>
      <SortBy history={history} parsedQs={parsedQs} />
    </SearchTopContent>
    <SortByAlert parsedQs={parsedQs} dispatchMetrics={undefined} />
  </>
);

export const SidebarAds = ({ routeId, publications }) => (
  <SidebarAdsContent>
    <BlackboxOuter>
      <BlackboxPosition routeId={routeId} position="sidebarbottom1" publication={publications?.sidebarbottom1} />
    </BlackboxOuter>
    <BlackboxOuter>
      <BlackboxPosition routeId={routeId} position="sidebarbottom2" publication={publications?.sidebarbottom2} />
    </BlackboxOuter>
  </SidebarAdsContent>
);

export const ToggleFilters = ({ data, history, isMarketEnabled }) => {
  const fastDeliveryFilter = data?.search?.aggregations?.find(agg => agg.id === 'one_day_delivery');

  if (!fastDeliveryFilter && !isMarketEnabled) {
    return null;
  }

  return (
    <ToggleFilterList>
      {fastDeliveryFilter && (
        <ToggleFilter data={data} history={history} filterSlug="one_day_delivery">
          entrega&nbsp;<strong>rápida</strong>
        </ToggleFilter>
      )}
      {isMarketEnabled && (
        <ToggleFilter data={data} history={history} filterSlug="origin">
          mercado
        </ToggleFilter>
      )}
    </ToggleFilterList>
  );
};

export const Filters = ({ data, parsedQs, history }) => (
  <FiltersContent
    filters={data.search.aggregations}
    history={history}
    rc={parsedQs.rc}
    context={parsedQs.context}
    modalHash="more-filters"
  />
);

const ToggleFilterList = styled.div`
  border-bottom: solid 1px #ccc;
  margin-top: 19px;
`;

const SidebarAdsContent = styled.div`
  max-width: 160px;
  margin-top: 28px;

  [class*='StyledGrid'] {
    margin: 0;
  }

  [class*='StyledCol'] {
    padding: 0;
  }

  [class*='src__Picture'] {
    position: static;
  }
`;

const BlackboxOuter = styled.div`
  margin-top: 34px;
`;

const SearchTopContent = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 12px;
  border-top: solid 2px #eee;
`;
