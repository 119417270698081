import React, { useState } from 'react';
import styled from 'styled-components';
import { getIn } from 'seamless-immutable';
import { Grid, Col } from '@catalogo/theme-grid';
import ProductCard from '@catalogo/ui-americanas-desktop-new-product-card';
import ProductCardList from '@catalogo/ui-americanas-desktop-product-card-list';
import Pagination from '@catalogo/ui-americanas-mobile-pagination';
import { DisplayOptions } from '@catalogo/ui-americanas-mobile-product-grid/src/components/display-options';
import { EmptyProducts } from '@catalogo/ui-americanas-mobile-product-grid/src/empty-products';
import SortByAlert from '@catalogo/ui-americanas-desktop-sort-by-alert';
import SortBy from './components/sort-by';

export const ProductGrid = ({ products, dispatchMetrics, parsedQs, sortByList, history, term, ...props }) => {
  const [viewMode, setViewMode] = useState(getIn(parsedQs, ['viewMode'], 'list'));
  const offset = parsedQs?.offset || 0;
  const limit = parsedQs?.limit || 24;

  const hasProducts = products?.length > 0;

  if (!hasProducts) {
    return (
      <GridItem justifyContent="center">
        <EmptyProducts content={term} />
      </GridItem>
    );
  }

  const renderGrid = product => (
    <ColGridItem sm={3} md={3} key={`${product?.product?.id}`}>
      <ProductCard product={product} />
    </ColGridItem>
  );

  const renderList = product => (
    <Col sm={12} key={`${product?.product?.id}`}>
      <ProductCardList product={product} />
    </Col>
  );

  return (
    <>
      <SortOptionsWrapper>
        <SortBy history={history} parsedQs={parsedQs} sortByList={sortByList} />
        <DisplayOptionsUI
          viewMode={viewMode}
          setViewMode={setViewMode}
          location={history?.location}
          parsedQs={parsedQs}
          sortByList={sortByList}
          history={history}
        />
      </SortOptionsWrapper>
      <SortByAlert parsedQs={parsedQs} />
      <GridItem justifyContent="flex-start">
        {products.map(product =>
          product.product ? (viewMode === 'grid' ? renderGrid(product) : renderList(product)) : null
        )}
      </GridItem>
      <BottomGrid>
        <Pagination
          maxButtons={8}
          history={history}
          parsedQs={parsedQs}
          dispatchMetrics={dispatchMetrics}
          offset={offset}
          limit={limit}
          {...props}
        />
      </BottomGrid>
    </>
  );
};

export default ProductGrid;

const SortOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
`;

const GridItem = styled(Grid)`
  margin-top: 32px;
  gap: 24px;
`;
const ColGridItem = styled(Col)`
  flex: 1 0 25%;
  max-width: 315px;
`;

const BottomGrid = styled.div`
  margin: 36px 0;
`;

const DisplayOptionsUI = styled(DisplayOptions)`
  svg {
    width: 24px;
    height: 24px;
  }
  span {
    font-size: 16px;
  }
`;
