import { stringify } from 'querystring';
import React from 'react';
import styled from 'styled-components';
import { getIn } from 'seamless-immutable';
import Arrows from '@catalogo/ui-americanas-desktop-svg/sort.svg';
import PropTypes from 'prop-types';

export const SortBy = ({ history, parsedQs, className }) => {
  const order = getIn(parsedQs, ['sortBy']) || getIn(parsedQs, ['ordenacao']) || 'relevance';

  const setSelectedOption = e => {
    const newQueryString = {
      ...parsedQs,
      sortBy: e.target.value,
    };
    delete newQueryString.ordenacao;
    updateUrl(newQueryString);
  };

  const updateUrl = newQueryString => {
    history.replace(`?${stringify(newQueryString)}`);
  };

  const orders = [
    { key: 'lowerPriceRelevance', value: 'menores preços' },
    { key: 'higherPriceRelevance', value: 'maiores preços' },
    { key: 'relevance', value: 'mais relevantes' },
    { key: 'topSelling', value: 'mais vendidos' },
    { key: 'rating', value: 'mais avaliados' },
  ];

  return (
    <SortByWrapper className={className}>
      <SortButtonIcon />
      <DivUI>
        <SortTitle>Ordenar</SortTitle>
        <SortTypeSelect id="sort-by" name="sort-by" defaultValue={order} onChange={setSelectedOption}>
          {orders.map(({ key, value }) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </SortTypeSelect>
      </DivUI>
    </SortByWrapper>
  );
};

SortBy.propTypes = {
  search: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  parsedQs: PropTypes.object,
};

const SortByWrapper = styled.form`
  border: none;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 16px;
  &:first-of-type {
    margin-left: 0;
  }
`;

const DivUI = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
`;

const SortButtonIcon = styled(Arrows)`
  fill: ${({ theme }) => theme.primaryColor};
  width: 16px;
  height: auto;
`;

const SortTitle = styled.span`
  display: flex;
  color: ${({ theme }) => theme.primaryColor};
  text-transform: ${({ theme }) => theme.titleTransform};
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin: 0 8px 0 4px;
`;

const SortTypeSelect = styled.select`
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.grey.dark};
  text-transform: ${({ theme }) => theme.titleTransform};
  font-size: ${({ theme }) => theme.fontSize.xs};
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  &:hover {
    cursor: pointer;
  }
`;

export default SortBy;
