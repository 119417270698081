import React, { useState, useRef, RefObject } from 'react';
import styled from 'styled-components';
import ArrowIcon from '@catalogo/theme-svg/arrow.svg';
import { withRouter } from 'react-router-dom';
import { fmtSuggestionHref } from '@catalogo/ui-americanas-mobile-suggestions/src/helpers/pageTypes';
import { filterSuggestions } from '@catalogo/ui-americanas-mobile-suggestions/src/helpers/filter-suggestions';
import { SuggestionsComponent, searchSuggestions, TComposedComponent } from './interfaces';

export const Suggestions: React.FC<SuggestionsComponent> = ({ defaultSearchSuggestions, location }) => {
  const getHeight = (active: boolean, contentRef: RefObject<HTMLElement>) =>
    active ? '50px' : `${contentRef?.current?.scrollHeight}px`;

  const [active, setActiveState] = useState(false);
  const [height, setHeightState] = useState('50px');

  const contentRef = useRef(null);

  const shouldSeeMore = (defaultSearchSuggestions: searchSuggestions[]) => defaultSearchSuggestions?.length > 8;

  const toggleAccordion = () => {
    const accordionHeight = getHeight(active, contentRef);

    setActiveState(!active);
    setHeightState(accordionHeight);
  };

  const pageType = location?.pathname?.split('/')[1];

  const suggestionList = filterSuggestions(defaultSearchSuggestions);

  const suggestedList = (defaultSearchSuggestions: searchSuggestions[], height: string) => (
    <List ref={contentRef} style={{ maxHeight: `${height}` }}>
      {defaultSearchSuggestions?.map(item => (
        <ListItem key={item.term}>
          <Link href={fmtSuggestionHref(item.href, pageType)}>{item.term}</Link>
        </ListItem>
      ))}
    </List>
  );

  if (suggestionList?.length === 0) {
    return <></>;
  }

  return (
    <>
      <Wrapper>
        <Title>sugestões de produtos</Title>
        {suggestedList(suggestionList, height)}
        {shouldSeeMore(suggestionList) && (
          <TextWrapper onClick={toggleAccordion}>
            <Text>{active ? 'ver menos' : 'ver mais'} sugestões</Text>
            <ArrowButton>
              <ArrowIconUI $active={active} />
            </ArrowButton>
          </TextWrapper>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding: 20px 0 20px 0;
  overflow: hidden;
`;

const Title = styled.h2`
  padding: 12px 0 10px 10px;
  font-size: 16px;
  font-weight: bold;
  color: #666;
`;

const Text = styled.p`
  font-size: 12px;
  font-weight: bold;
  font-family: Helvetica, Arial, sans-serif;
  color: #666;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 9px 4px 17px 10px;
  cursor: pointer;
`;

const List = styled.ul`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  transition: max-height 0.5s 0s ease-in-out;
`;

const ListItem = styled.li`
  background-color: #fff;
  border-radius: 4px;
  margin-right: 20px;
  padding: 8px;
  margin: 6px;
`;

const Link = styled.a`
  line-height: 100%;
  font-weight: bold;
  font-size: 12px;
  font-family: Helvetica, Arial, sans-serif;
  color: #666;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const ArrowButton = styled.button`
  background: none;
  border: none;

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.primaryColor};
    border-radius: 2px;
  }
`;

const ArrowIconUI = styled(ArrowIcon)<{ $active: boolean }>`
  fill: ${({ theme }) => theme.titleColor};
  width: 10px;
  height: 10px;
  display: flex;
  flex-direction: column;
  align-self: center;
  transition: transform 0.6s ease;
  ${({ $active }) => ($active ? 'transform: rotate(-180deg)' : '')};
`;

const SuggestionsComposed = (withRouter(Suggestions) as unknown) as TComposedComponent;

export default SuggestionsComposed;
