import { stringify } from 'querystring';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import Grid from '@catalogo/ui-americanas-desktop-svg/grid.svg';
import List from '@catalogo/ui-americanas-desktop-svg/list.svg';
import { withMetrics } from '@catalogo/core-metrics';
import { getIn } from 'seamless-immutable';

export const DisplayOptions = ({ viewMode, setViewMode, location, parsedQs, history, className }) => {
  const isGrid = viewMode === 'grid';
  const viewModeQs = getIn(parsedQs, ['viewMode'], '');
  useEffect(() => {
    if (!isGrid || viewModeQs) {
      const newQs = {
        ...parsedQs,
        viewMode,
      };
      const { hash } = history.location;
      const hasModal = hash?.indexOf('#modal-') !== -1;

      let newUrl = `${location.pathname}?${stringify(newQs)}`;

      if (hasModal) {
        newUrl += history.location.hash;
      }

      history.push(newUrl);
    }
  }, [viewMode]);

  const handleChangeViewMode = (option, viewMode) => {
    if (viewMode !== option) {
      setViewMode(option);
    }
  };

  return (
    <DisplayOptionsContainer className={className}>
      <DisplayOptionsBox>
        <ViewModeButton selected={!isGrid} onClick={() => handleChangeViewMode('list', viewMode)}>
          <ListButtonIcon selected={!isGrid} />
          <ButtonText selected={!isGrid}>Lista</ButtonText>
        </ViewModeButton>

        <ViewModeButton selected={isGrid} onClick={() => handleChangeViewMode('grid', viewMode)}>
          <GridButtonIcon selected={isGrid} />
          <ButtonText selected={isGrid}>Grade</ButtonText>
        </ViewModeButton>
      </DisplayOptionsBox>
    </DisplayOptionsContainer>
  );
};

export default withMetrics(DisplayOptions);

const DisplayOptionsContainer = styled.div``;

const DisplayOptionsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ViewModeButton = styled.button`
  ${({ selected }) => css`
    border: none;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 16px;
    padding: 0;
    ${!selected &&
    css`
      cursor: pointer;
    `}

    &:first-of-type {
      margin-left: 0;
    }
  `}
`;

const GridButtonIcon = styled(Grid)`
  fill: ${({ theme, selected }) => (selected ? theme.primaryColor : theme.grey.medium)};
  width: 16px;
  height: auto;
`;

const ListButtonIcon = styled(List)`
  fill: ${({ theme, selected }) => (selected ? theme.primaryColor : theme.grey.medium)};
  width: 16px;
  height: auto;
`;

const ButtonText = styled.span`
  color: ${({ theme, selected }) => (selected ? theme.primaryColor : theme.grey.medium)};
  text-transform: ${({ theme }) => theme.titleTransform};
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding: 0 0 0 4px;
`;
