import React from 'react';
import styled from 'styled-components';
import AdsBadge from '../atoms/ads-badge';

const BottomBadges = cardProps => (
  <Container>
    <BadgeWrapper>
      <AdsBadge {...cardProps} />
    </BadgeWrapper>
  </Container>
);

const BadgeWrapper = styled.div`
  margin-bottom: 31px;
`;

const Container = styled.div`
  display: grid;
  gap: 2px;

  &:empty {
    display: none;
  }
`;

export default BottomBadges;
