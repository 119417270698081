import React from 'react';
import Loading from '@catalogo/ui-shoptime-mobile-loading';
import * as Styled from './styles';

const SearchLoading = () => (
  <Styled.Content>
    <Loading />
  </Styled.Content>
);

export default SearchLoading;
