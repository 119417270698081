import React from 'react';
import styled, { css } from 'styled-components';

const BrandName = ({ name, className }) => <Name className={className}>{name}</Name>;

const Name = styled.span`
  ${({ theme }) => css`
    color: ${theme.grey.dark};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  `}
`;

export default BrandName;
