import React from 'react';
import BlackboxArea from '@catalogo/core-blackbox/area';
import ProductAdsCard from '@catalogo/ui-americanas-desktop-product-ads-card/src';
import HasNoProducts from './components/has-no-products';
import ProductResults from './components/product-results';
import { IProps } from './types';
import * as Styled from './styles';

export const MiddleArea: React.FC<IProps> = ({
  routeId,
  publications,
  history,
  parsedQs,
  data,
  products,
  fetchMore,
  dispatchMetrics,
  isSpecialSeller,
  isSpecialOrGridContent,
  sortByList,
}) => (
  <>
    <Styled.Wrapper>
      {isSpecialOrGridContent && (
        <>
          <ProductAdsCard
            publications={publications}
            pageType="category"
            positionAds="category.1"
            positionBrand="keyword.category.1"
          />
          <BlackboxArea area="middleTop" routeId={routeId} publications={publications} />
        </>
      )}
      {!!products && products?.length > 0 && (
        <ProductResults
          data={data}
          parsedQs={parsedQs}
          history={history}
          products={products}
          dispatchMetrics={dispatchMetrics}
          fetchMore={fetchMore}
          publications={publications}
          sortByList={sortByList}
          isSpecialOrGridContent={isSpecialOrGridContent}
        />
      )}
      {!isSpecialSeller && (!products || products?.length === 0) && <HasNoProducts data={data} />}
      {isSpecialOrGridContent && (
        <>
          <BlackboxArea area="middle" routeId={routeId} publications={publications} />
          <BlackboxArea area="bottom" routeId={routeId} publications={publications} />
        </>
      )}
    </Styled.Wrapper>
  </>
);

export default MiddleArea;
