import React from 'react';
import { Col } from '@catalogo/theme-grid';
import BlackboxArea from '@catalogo/core-blackbox/area';
import Filters from '@catalogo/ui-americanas-desktop-filters';
import SelectedFilter from '@catalogo/ui-americanas-desktop-selected-filter';
import { EmptyProducts } from '@catalogo/ui-americanas-mobile-product-grid/src/empty-products';
import styled from 'styled-components';
import ProductAdsCard from '@catalogo/ui-americanas-desktop-product-ads-card/src';
import AsideBlackboxPositions from './aside-positions';
import GridArea from './grid-area';
import ListArea from './list-area';

const Product = ({ data, parsedQs, history, products, dispatchMetrics, fetchMore, isToRenderList }) => {
  if (!products) {
    return null;
  }

  if (products?.length <= 0) {
    return <EmptyProducts />;
  }

  return isToRenderList ? (
    <ListArea
      data={data}
      parsedQs={parsedQs}
      history={history}
      products={products}
      dispatchMetrics={dispatchMetrics}
      fetchMore={fetchMore}
    />
  ) : (
    <GridArea
      data={data}
      parsedQs={parsedQs}
      history={history}
      products={products}
      dispatchMetrics={dispatchMetrics}
      fetchMore={fetchMore}
    />
  );
};

const MiddleArea = ({
  routeId,
  publications,
  positions,
  defaultSearchSuggestions,
  aggregations,
  history,
  parsedQs,
  selectedAggregations,
  cleanAll,
  data,
  products,
  fetchMore,
  dispatchMetrics,
  experiment,
  isToRenderList,
}) => (
  <>
    <Col md={2}>
      <AsideBlackboxPositions routeId={routeId} publications={publications} positions={positions} />
      <Filters
        defaultSearchSuggestions={defaultSearchSuggestions}
        filters={aggregations}
        history={history}
        rc={parsedQs.rc}
        context={parsedQs.context}
        modalHash="more-filters"
      />
    </Col>
    <WrapperRight>
      <ProductAdsCard
        publications={publications}
        pageType="category"
        dispatchMetrics={dispatchMetrics}
        positionAds="category.1"
        positionBrand="keyword.category.1"
        isSecondaryLayout={true}
      />
      <BlackboxArea area="middleTop" routeId={routeId} publications={publications} />
      <SelectedFilter
        parsedQs={parsedQs}
        selectedAggregations={selectedAggregations}
        history={history}
        aggregations={aggregations}
        cleanAll={cleanAll}
      />
      <Product
        data={data}
        parsedQs={parsedQs}
        history={history}
        products={products}
        dispatchMetrics={dispatchMetrics}
        fetchMore={fetchMore}
        experiment={experiment}
        isToRenderList={isToRenderList}
      />
      <BlackboxArea area="middle" routeId={routeId} publications={publications} />
      <BlackboxArea area="bottom" routeId={routeId} publications={publications} />
    </WrapperRight>
  </>
);

const WrapperRight = styled(Col)`
  margin: 0 auto;
  max-width: 1000px;
  padding: 10px 0 0;
`;
export default MiddleArea;
