import React from 'react';
import { getIn } from 'seamless-immutable';
import compose from 'lodash/fp/compose';
import { withMetrics } from '@catalogo/core-metrics';
import { withCookies } from '@catalogo/core-connect/cookies';
import { withNavigation } from '@catalogo/core-connect/navigation';
import { mergeComponents } from '@catalogo/service-americanas-category/src/helpers/template';
import { useGetCategoryData, useGetCategoryDeferedData, useReloadCategoryNewCEP } from '../hooks/category';
import { IParsedQs } from '../types/global';
import { IWithCategoryProps } from '../types/hocs/category';

const verifyCategory = (category: string) =>
  !['eletroportáteis', 'celulares e smartphones', 'informática', 'tv e home theater', 'eletrodomésticos'].find(
    str => !!str.includes(category.toLowerCase())
  );

export const withCategory = (WrappedComponent: React.FC) => {
  const EnhancedCategory = ({
    history,
    location,
    routeId,
    parsedQs = {} as IParsedQs,
    match,
    dispatchMetrics,
    cookies,
    sortByList,
    ...props
  }: IWithCategoryProps) => {
    const pathname = getIn(location, ['pathname'], '');
    const sitepage = getIn(parsedQs, ['sitepage']) ? `/${getIn(parsedQs, ['sitepage'])}` : undefined;
    const term = getIn(match, ['params', 'term'], '');

    const { loading, categoryData, refetch, error, fetchMore, networkStatus } = useGetCategoryData({
      pathname,
      parsedQs,
      dispatchMetrics,
      routeId,
      sitepage,
    });

    const dataDefered = useGetCategoryDeferedData({
      location,
      match,
      mergeComponents,
      dispatchMetrics,
      page: { ...getIn(categoryData, ['page'], {}) },
    });

    const allData = dataDefered ? mergeComponents(categoryData, dataDefered) : categoryData;
    const products = getIn(allData, ['search', 'products'], []);
    const type = products ? 'category' : 'department';
    const publications = getIn(allData, ['page', 'components'], {});
    const category = getIn(categoryData, ['page', 'breadcrumb', 0, 'name'], '');

    useReloadCategoryNewCEP({
      cookies,
      history,
    });

    const componentProps = {
      data: allData,
      error,
      products,
      history,
      location,
      loading,
      parsedQs,
      cookies,
      refetch,
      routeId,
      term,
      publications,
      fetchMore,
      type,
      networkStatus,
      dispatchMetrics,
      sortByList,
      isToRenderList: !verifyCategory(category),
    };

    return <WrappedComponent {...componentProps} {...props} />;
  };

  return compose(withCookies, withMetrics, withNavigation)(EnhancedCategory);
};
