import React from 'react';
import styled from 'styled-components';
import { SalesPrice, ListPrice, Installment } from '@catalogo/theme-product-card';
import { DiscountBadge } from '@catalogo/theme-badges';

const PriceInfo = ({ price, isSecondaryLayout }) => (
  <Wrapper>
    <SalesDiscount $isSecondaryLayout={isSecondaryLayout}>
      <SalesPrice {...price} />
      <DiscountBadge {...price} />
    </SalesDiscount>
    <PriceInstalmentCash $isSecondaryLayout={isSecondaryLayout}>
      <ListPrice {...price} />
      <Installment {...price} />
    </PriceInstalmentCash>
  </Wrapper>
);

export default PriceInfo;

const Wrapper = styled.div`
  width: 143px;
`;

const PriceInstalmentCash = styled.div`
  gap: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? 'relative' : 'absolute')};
  top: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? '0' : '56px')};

  .list-price {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
  }

  .installment {
    font-size: 14px;
    line-height: 15px;
    height: auto;
  }
`;

const SalesDiscount = styled.div`
  gap: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 4px;
  position: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? 'relative' : 'absolute')};
  top: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? '0' : '36px')};

  &:empty {
    display: none;
  }

  .sales-price {
    font-size: 14px;
    line-height: 15px;
  }

  .discount-badge {
    &-text {
      font-size: ${({ theme }) => theme.fontSize.xxs};
    }
  }
`;
