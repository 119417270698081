/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import React from 'react';
import { getIn } from 'seamless-immutable';
import compose from 'lodash/fp/compose';
import { withMetrics } from '@catalogo/core-metrics';
import { withCookies } from '@catalogo/core-connect/cookies';
import { withNavigation } from '@catalogo/core-connect/navigation';
import { withDevice, WithDeviceProps as IWithDeviceProps } from '@catalogo/core-connect/device';
import { mergeComponents } from '@catalogo/service-americanas-category/src/helpers/template';
import { useHeaderContext } from '@catalogo/service-americanas-header/src/context/header';
import withNotFoundRedirect from '@catalogo/service-americanas-redirect/src/hoc/not-found';
import { useGetSellerData, useGetSellerDeferedData, useHeaderInputLojista } from '../hooks/seller';
import { TProducts } from '../types/global';
import { IWithSellerProps, IInjectedProps } from '../types/hocs/seller';
import { checkIsContextBrand } from '../helpers/context';

export const withSeller = (Component: React.FC<IWithSellerProps & IInjectedProps>) => (
  props: IWithSellerProps & IWithDeviceProps
) => {
  const { history, location, routeId, parsedQs = {}, match, dispatchMetrics, sortByList, turboData } = props;

  const pathname = getIn(location, ['pathname'], '');
  const sitepage = getIn(parsedQs, ['sitepage']) ? `/${getIn(parsedQs, ['sitepage'])}` : undefined;
  const sellerId = turboData?.id;
  const content = decodeURIComponent(parsedQs?.conteudo || '')
    .replace(/\+/g, ' ')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-');

  const { loading, sellerData, refetch, error, fetchMore, networkStatus } = useGetSellerData({
    content: content,
    pathname,
    parsedQs,
    dispatchMetrics,
    routeId,
    sitepage,
    sellerId,
  });

  const { context } = useHeaderContext();
  const sellerName = getIn(match, ['params', 'seller'], '');
  const isContextBrand = checkIsContextBrand(context);
  const newContext = isContextBrand ? context : sellerName;
  const name = sellerData?.page?.name?.toLowerCase();
  const placeholder = isContextBrand ? 'busque aqui seu produto' : `busque aqui, produtos exclusivos ${name}`;

  useHeaderInputLojista({
    slug: sellerName,
    content: 'lojista',
    context: newContext,
    name: sellerData?.page?.name,
    placeholder: placeholder ?? '',
  });

  const dataDefered = useGetSellerDeferedData({
    location,
    match,
    mergeComponents,
    dispatchMetrics,
    page: { ...getIn(sellerData, ['page'], {}) },
    sellerId,
  });

  const sellerAbout = sellerData?.seller;

  const allData = dataDefered ? mergeComponents(sellerData, dataDefered) : sellerData;

  const isSpecialSeller = !sellerData?.search?.products && Object.keys(sellerData?.page?.components ?? {})?.length > 0;

  const hasSearchTerm = !!parsedQs?.conteudo;

  const hasSortBy = !!parsedQs?.sortBy;

  const hasFilter =
    (allData?.page?.filters ?? [])?.filter((filter: { id: string }) => filter?.id !== 'seller')?.length > 0;

  const isSpecialOrGridContent = isSpecialSeller || hasFilter || hasSearchTerm || hasSortBy;

  const products = (getIn(allData, ['search', 'products'], []) as TProducts)?.filter(product => !!product?.product);

  const hasSellerAbout = sellerAbout && products && products.length > 0 ? true : false;

  const publications = getIn(allData, ['page', 'components'], {});

  const breadcrumbData = getIn(allData, ['page', 'breadcrumb'], []);

  const injectedProps = {
    data: allData,
    error,
    products,
    history,
    loading,
    parsedQs,
    refetch,
    routeId,
    term: context,
    publications,
    fetchMore,
    networkStatus,
    dispatchMetrics,
    sortByList,
    sellerAbout: { ...sellerAbout, hasSellerAbout },
    breadcrumbData,
    isSpecialSeller,
    isSpecialOrGridContent,
  };

  return <Component {...injectedProps} {...props} />;
};

export default compose(withCookies, withMetrics, withNavigation, withDevice, withSeller, withNotFoundRedirect);
