import DiamondBadge from '@catalogo/ui-americanas-desktop-svg/badge-diamond.svg';
import GoldBadge from '@catalogo/ui-americanas-desktop-svg/badge-gold.svg';
import SilverBadge from '@catalogo/ui-americanas-desktop-svg/badge-silver.svg';
import BronzeBadge from '@catalogo/ui-americanas-desktop-svg/badge-bronze.svg';

import SalesLevel1 from '@catalogo/ui-americanas-desktop-svg/sales-level-1.svg';
import SalesLevel2 from '@catalogo/ui-americanas-desktop-svg/sales-level-2.svg';
import SalesLevel3 from '@catalogo/ui-americanas-desktop-svg/sales-level-3.svg';
import SalesLevel4 from '@catalogo/ui-americanas-desktop-svg/sales-level-4.svg';
import SalesLevel5 from '@catalogo/ui-americanas-desktop-svg/sales-level-5.svg';

type SellerBadgeObject = {
  [key: string]: {
    title: string;
    description: string;
    image: React.FC<React.SVGAttributes<SVGElement>>;
  };
};

type SellerSalesObject = {
  [key: string]: {
    range: string;
  };
};

export const getSellerBadge = (badge: string) => {
  const badgeInlowerCase = badge?.toLocaleLowerCase();

  const object = ({
    'loja oficial': {
      title: 'Loja Diamante',
      description: 'Conhecida no mercado e bem avaliada',
      image: DiamondBadge,
      sortByRate: 5,
    },
    ouro: {
      title: 'Loja Ouro',
      description: 'Campeã de vendas e bem avaliada',
      image: GoldBadge,
      sortByRate: 4,
    },
    prata: {
      title: 'Loja Prata',
      description: 'Alto volume de vendas e bem avaliada',
      image: SilverBadge,
      sortByRate: 3,
    },
    bronze: {
      title: 'Loja Bronze',
      description: 'Destaque de vendas e bem avaliada',
      image: BronzeBadge,
      sortByRate: 2,
    },
  } as unknown) as SellerBadgeObject;

  return object[badgeInlowerCase];
};

export const getSellerSales = (salesRange: string) => {
  const salesInlowerCase = salesRange?.toLocaleLowerCase();

  const object = ({
    'loja faixa 1': { range: SalesLevel1, level: 1 },
    'loja faixa 2': { range: SalesLevel2, level: 2 },
    'loja faixa 3': { range: SalesLevel3, level: 3 },
    'loja faixa 4': { range: SalesLevel4, level: 4 },
    'loja faixa 5': { range: SalesLevel5, level: 5 },
  } as unknown) as SellerSalesObject;

  return object[salesInlowerCase];
};
