import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { getIn } from 'seamless-immutable';
import { getPlacements } from '@catalogo/service-americanas-category/src/helpers/template';
import { setHeaderContext } from '@catalogo/service-americanas-header/src/context/header';
import { Seller } from '../queries/seller.graphql';
import { getDeferedSeller } from '../queries/defered.graphql';
import {
  IUseGetSellerDataParams,
  IUseGetSellerDeferedDataParams,
  IUseHeaderInputLojistaParams,
} from '../types/hooks/seller';

export const useGetSellerData = ({
  dispatchMetrics,
  parsedQs,
  pathname,
  routeId,
  sitepage,
  content,
  sellerId,
}: IUseGetSellerDataParams) => {
  const { sortBy = 'relevance', source = 'blancalojista', limit = 24, offset = 0 } = parsedQs;
  const pathnameWithContent = content ? `${pathname}?content=${content}` : pathname;
  const variables = {
    content,
    path: pathnameWithContent,
    sortBy,
    source,
    limit: typeof limit === 'number' ? limit : parseInt(limit),
    offset: typeof offset === 'number' ? offset : parseInt(offset),
    routeId,
    sitepage,
    sellerId,
  };

  const { loading, data = {}, refetch, error, previousData = {}, fetchMore, networkStatus } = useQuery(Seller, {
    variables,
    notifyOnNetworkStatusChange: true,
    onCompleted: /* istanbul ignore next */ data => {
      dispatchMetrics('page:load', data);
    },
  });

  const sellerData = loading ? previousData : data;

  return { loading, sellerData, refetch, error, fetchMore, networkStatus };
};

export const useGetSellerDeferedData = (props: IUseGetSellerDeferedDataParams) => {
  const { dispatchMetrics, mergeComponents, sellerId, page = {} } = props;

  const template = getIn(page, ['template'], {});

  const recPlacements = getPlacements('recommendation', template);

  const b2wAdsPlacements = getPlacements('b2wads', template);

  const { name } = page;

  const variables = {
    sellerId,
    recPlacements,
    b2wAdsPlacements,
    term: name ? name.toLowerCase() : '',
    pageType: 'seller',
  };

  const { data: dataDefered } = useQuery(getDeferedSeller, {
    variables,
    skip: recPlacements.length < 1 && b2wAdsPlacements.length < 1,
    ssr: false,
    onCompleted: /* istanbul ignore next */ data => {
      dispatchMetrics('recommendation:load', mergeComponents(page, data));
    },
  });

  return dataDefered;
};

export const useHeaderInputLojista = ({
  content,
  slug,
  context,
  name,
  placeholder,
  pathname = '',
}: IUseHeaderInputLojistaParams) => {
  useEffect(() => {
    if (context) {
      setHeaderContext({
        name,
        slug,
        placeholder: placeholder,
        context,
        formAction: `/${content}/${context}${pathname}`,
      });
    }
  }, [name, context]);
};
