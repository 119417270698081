import styled from 'styled-components';

export const Container = styled.main<{ isSpecialSeller: boolean }>`
  background-color: ${({ theme, isSpecialSeller }) => (isSpecialSeller ? theme?.white : theme?.grey?.lighter)};
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  max-width: 1274px;
  padding: 0 12px;
  margin: 0 auto;
`;
