import React from 'react';
import styled from 'styled-components';
import { LazyPicture } from '@catalogo/theme-lazy-picture';

const CardImage = ({ imageSource, name }) => (
  <WrapperPicture>
    <LazyPicture sources={imageSource} alt={name} />
  </WrapperPicture>
);

const WrapperPicture = styled.div`
  position: relative;
  width: 130px;
  height: 130px;

  img {
    mix-blend-mode: normal;
  }
`;

export default CardImage;
