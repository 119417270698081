import React from 'react';
import { getIn } from 'seamless-immutable';
import ProductList from '@catalogo/ui-americanas-desktop-product-grid/src/product-grid-new-list';

const ListArea = ({ data, parsedQs, history, products, fetchMore, dispatchMetrics }) => {
  const total = getIn(data, ['search', 'total'], 0);
  const productsIds = getIn(data, ['search', 'productIds'], []);
  const hasNextPage = getIn(data, ['search', 'hasNextPage'], false);

  const term = getIn(data, ['search', 'term'], false);

  return (
    <>
      <ProductList
        products={products}
        productsIds={productsIds}
        hasNextPage={hasNextPage}
        dispatchMetrics={dispatchMetrics}
        fetchMore={fetchMore}
        term={term}
        total={total}
        history={history}
        parsedQs={parsedQs}
      />
    </>
  );
};

export default ListArea;
