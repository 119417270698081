import React from 'react';
import styled from 'styled-components';
import { SalesPrice, ListPrice } from '../atoms/price';
import Installment from '../atoms/installment';
import Discount from '../atoms/discount-badge';

const PriceInfo = ({ price }) => (
  <Wrapper>
    <SalesDiscount>
      <SalesPrice {...price} />
      <Discount {...price} />
    </SalesDiscount>
    <PriceInstalmentCash>
      <ListPrice {...price} />
      <Installment {...price} />
    </PriceInstalmentCash>
  </Wrapper>
);

export default PriceInfo;

const Wrapper = styled.div``;

const PriceInstalmentCash = styled.div`
  display: grid;
  gap: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 86px;
`;

const SalesDiscount = styled.div`
  display: grid;
  gap: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 4px;
  position: absolute;
  top: 68px;

  &:empty {
    display: none;
  }
`;
