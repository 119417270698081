import React from 'react';
import { getIn } from 'seamless-immutable';
import { IProps } from './types';
import * as Styled from './styles';

export const HasNoProducts: React.FC<IProps> = ({ data }) => {
  const term = getIn(data, ['search', 'content', 'term'], '');

  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.Term>
          poxa, nenhum resultado encontrado
          {term && (
            <>
              &nbsp;para <strong>{`"${term.replace(/-/g, ' ')}"`}</strong>
            </>
          )}
          .
        </Styled.Term>
        <Styled.Subtitle>Que tal pesquisar de novo seguindo as dicas abaixo? ;)</Styled.Subtitle>
        <Styled.List>
          <Styled.ListItem>Confira se o termo foi digitado certinho;</Styled.ListItem>
          <Styled.ListItem>Use menos palavras ou termos menos específicos;</Styled.ListItem>
          <Styled.ListItem>
            Tente outro produto ou navegue pelos departamentos <br /> para encontrar o que você precisa.
          </Styled.ListItem>
        </Styled.List>
      </Styled.Wrapper>
      <Styled.Contact>
        <Styled.Content>
          <Styled.Title>precisa de ajuda? fale com a gente</Styled.Title>
          <Styled.Text>
            Nosso atendimento é de segunda a sexta, das <br />
            8h às 20h, e sábado, das 8h às 18h :)
          </Styled.Text>
        </Styled.Content>
        <Styled.Content>
          <Styled.ContactSubtitle>
            <Styled.PhoneUI />
            por telefone:
          </Styled.ContactSubtitle>
          <Styled.Text>
            Capitais e regiões metropolitanas: 4003-4848* <br />
            Estado do Rio de Janeiro: 0800 229 4848 <br />
            Outras regiões: 041 11 4003-4848*
          </Styled.Text>
          <Styled.SmallText>*pode ter custos adicionais</Styled.SmallText>
        </Styled.Content>
        <Styled.Content>
          <Styled.ContactSubtitle>
            <Styled.MailUI />
            por e-mail:
          </Styled.ContactSubtitle>
          <Styled.Text>atendimento.acom@americanas.com</Styled.Text>
          <Styled.Info>
            <Styled.QuestionUI />
            Se preferir, acesse nossas <Styled.Link to="/hotsite/atendimento">perguntas frequentes</Styled.Link> ;)
          </Styled.Info>
        </Styled.Content>
      </Styled.Contact>
    </Styled.Container>
  );
};

export default HasNoProducts;
