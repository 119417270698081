import React from 'react';
import styled, { css } from 'styled-components';
import { getIn } from 'seamless-immutable';

const AdsBadge = ({ sponsorName, stamps }) => {
  const adsMessage = getIn(stamps, ['adsMessage'], '');
  const sponsoredBy = adsMessage ? adsMessage.split(sponsorName)[0] : 'patrocinado por ';

  return (
    !!adsMessage && (
      <Badge>
        <BadgeText>
          {sponsoredBy}
          <strong>{sponsorName}</strong>
        </BadgeText>
      </Badge>
    )
  );
};

const Badge = styled.div``;

const BadgeText = styled.p`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: 12px;
    line-height: 15px;
  `}
`;

export default AdsBadge;
