import React from 'react';
import styled from 'styled-components';
import { getIn, without } from 'seamless-immutable';
import Breadcrumb from '@catalogo/theme-breadcrumb';
import { Col } from '@catalogo/theme-grid';
import BlackboxArea from '@catalogo/core-blackbox/area';
import { getTotalResultsText } from '@catalogo/ui-americanas-mobile-filter-menu/src/helpers';

const Total = ({ total }) => <TotalText>{getTotalResultsText(total)}</TotalText>;

const TopArea = ({ data, routeId, publications, isToRenderList, products }) => {
  const { h1, name } = data?.page || {};
  const title = h1 || name;
  const breadcrumbData = getIn(data, ['page', 'breadcrumb'], []);
  const total = getIn(data, ['search', 'total'], 0);
  const filteredPublications = without(publications, 'category_page.ads1');

  return (
    <TopWrapper>
      <BreacrumbContainer>
        <Breadcrumb directionIcon="right" size="8px" textSize="12px" breadcrumb={breadcrumbData?.slice(0)?.reverse()} />
      </BreacrumbContainer>
      {!!title && <Title>{title}</Title>}
      {isToRenderList && !!products && <Total total={total} />}
      <Col md={12}>
        <BlackboxArea area="top" routeId={routeId} publications={filteredPublications} />
      </Col>
    </TopWrapper>
  );
};

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BreacrumbContainer = styled.div`
  margin: 20px 0 15px;
`;

const Title = styled.h1`
  padding: 15px 0px;
  text-transform: lowercase;
  font-size: 28px;
  font-weight: bold;
  color: ${({ theme }) => theme.grey.dark};
`;

const TotalText = styled.span`
  color: #666;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 14px;
`;

export default TopArea;
