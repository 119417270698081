import Immutable, { getIn } from 'seamless-immutable';

export const getPlacements = (placementType, template) => {
  const { top, middle, middleBottom, bottom } = template || {};

  const topArray = top ? top : [];
  const middleArray = middle ? middle : [];
  const middleBottomArray = middleBottom ? middleBottom : [];
  const bottomArray = bottom ? bottom : [];

  const placementsTop = topArray.filter(position => position.type === placementType).map(position => position.id);

  /* istanbul ignore next */
  const placementsMiddle = middleArray.filter(position => position.type === placementType).map(position => position.id);
  /* istanbul ignore next */
  const placementsMiddleBottom = middleBottomArray
    .filter(position => position.type === placementType)
    .map(position => position.id);
  /* istanbul ignore next */
  const placementsBottom = bottomArray.filter(position => position.type === placementType).map(position => position.id);

  return placementsTop.concat(placementsMiddle, placementsMiddleBottom, placementsBottom);
};

export const mergeComponents = (data = {}, dataDefered = {}) => {
  const components = Object.assign(
    {},
    getIn(data, ['page', 'components'], {}),
    getIn(dataDefered, ['recommendations', 'components'], {}),
    getIn(dataDefered, ['newtail', 'components'], {}),
    getIn(dataDefered, ['b2wAds'], {})
  );

  return Immutable.setIn(data, ['page', 'components'], components);
};
