import React, { useEffect } from 'react';
import { getIn } from 'seamless-immutable';
import compose from 'lodash/fp/compose';
import { withOneDayDelivery } from '@catalogo/core-connect/one-day-delivery';
import { withRouter } from 'react-router';
import { withMetrics } from '@catalogo/core-metrics';
import { useGetProductAd } from '@catalogo/service-americanas-search/src/hooks/search';
import { sendBeacon } from '@catalogo/core-send-beacon';
import { getImage, getImageBrand, isBrand } from '../helpers/image-and-stamps';
import { getLink, getOneDayDelivery, getPfmParamsAds } from '../helpers/url-builder';
import { getDiscount, getInstallmentPhrase, getPrice, getPromotionalPrice } from '../helpers/price-values';
import { handleMetrics } from '../helpers/metrics';
import { PropsCardBrandProps, WithProductAdsProps } from '../types';

const PropsCardBrand = (props: PropsCardBrandProps) => {
  const { title, headline, storeUrl, sendMetrics } = props;

  return {
    name: title,
    headline: headline,
    imageSource: getImageBrand(props),
    url: storeUrl,
    handleClick: sendMetrics,
    stamps: {
      adsMessage: isBrand(props),
    },
    loading: false,
    called: true,
  };
};

export const PropsCardPatrocinado = (
  product: {
    name: string;
    rating: string;
    compliance: {
      nursling: boolean;
    };
  },
  pfmParams: string,
  oneDayDeliveryParam: string,
  sendMetrics: () => void,
  loading: boolean,
  called: boolean,
  sponsorName: string
) => {
  const offer = getIn(product, ['offers', 'result', 0], null);
  const isNurslingProduct = product?.compliance?.nursling;

  return {
    name: product.name,
    rating: product.rating,
    outOfStock: !offer || !offer?.salesPrice,
    imageSource: getImage(product),
    url: getLink(product, pfmParams, oneDayDeliveryParam),
    handleClick: sendMetrics,
    price: {
      installmentPhase: getInstallmentPhrase(product),
      price: getPrice(product, isNurslingProduct),
      promotionalPrice: getPromotionalPrice(product),
      discount: getDiscount(product, isNurslingProduct),
    },
    stamps: {
      adsMessage: isBrand({ sponsorName }),
    },
    loading: loading,
    called: called,
  };
};

// eslint-disable-next-line max-statements
export const withProductAds = (Component: React.FC) => (props: WithProductAdsProps) => {
  const {
    oneDayDeliveryFiltered,
    positionCard,
    position,
    dispatchMetrics,
    sponsorName,
    publication,
    pageType,
    trackingUrl,
    impressionTrackingUrl,
  } = props;

  useEffect(() => {
    if (impressionTrackingUrl) {
      sendBeacon(impressionTrackingUrl);
    }
  }, []);

  const brand = props?.brand || false;

  const sendMetricsAds = () => handleMetrics({ trackingUrl }, position, positionCard, dispatchMetrics, brand);

  if (brand) {
    return <Component {...props} {...PropsCardBrand({ sendMetrics: sendMetricsAds, ...props })} />;
  }

  const ads = [
    {
      id: props?.id,
      sellerId: props?.sellerId,
      trackingUrl: props?.trackingUrl,
      queryString: props?.queryString,
    },
  ];

  const { productAd, loading, called = false } = useGetProductAd(ads, publication, position, pageType);

  if (!productAd) {
    const componentProps = { loading, sendMetrics: sendMetricsAds, called };
    return <Component {...props} {...componentProps} />;
  }

  const product = getIn(productAd, ['product']);

  const sendMetrics = () => handleMetrics(product, position, positionCard, dispatchMetrics, brand);

  const pfmParams = getPfmParamsAds(positionCard, position, publication, pageType);

  const oneDayDeliveryParam = getOneDayDelivery(oneDayDeliveryFiltered);

  return (
    <Component
      {...props}
      {...PropsCardPatrocinado(product, pfmParams, oneDayDeliveryParam, sendMetrics, loading, called, sponsorName)}
    />
  );
};

export default compose(withRouter, withMetrics, withOneDayDelivery, withProductAds);
