import React from 'react';
import Filters from '@catalogo/ui-americanas-desktop-filters';
import * as Styled from './styles';
import { GridArea } from './components/grid-area';
import { ContentCarousels } from './components/content-carousels';
import { IProps } from './types';

const ProductResults: React.FC<IProps> = ({
  data,
  parsedQs,
  history,
  products,
  fetchMore,
  dispatchMetrics,
  publications,
  sortByList,
  isSpecialOrGridContent,
}) => (
  <>
    {isSpecialOrGridContent ? (
      <Styled.Wrapper>
        <Filters
          filters={data?.search?.aggregations}
          history={history}
          rc={parsedQs?.rc}
          context={parsedQs?.context}
          modalHash="more-filters"
        />
        <Styled.WrapperRight>
          <GridArea
            data={data}
            parsedQs={parsedQs}
            history={history}
            products={products}
            fetchMore={fetchMore}
            dispatchMetrics={dispatchMetrics}
            sortByList={sortByList}
          />
        </Styled.WrapperRight>
      </Styled.Wrapper>
    ) : (
      <ContentCarousels history={history} publications={publications} products={products} />
    )}
  </>
);

export default ProductResults;
