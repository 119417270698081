import React from 'react';
import { getIn } from 'seamless-immutable';
import { withConfig } from '@catalogo/core-connect/config';
import { getSearchSchema } from './search';

export const fmtOrganizationSchema = ({ config }) => {
  const { shortName, domainUrl, email, socialMedia, ogImage } = config;

  return {
    '@type': 'Organization',
    id: '#organization',
    name: shortName,
    legalName: 'B2W - Companhia Digital',
    url: domainUrl,
    sameAs: socialMedia,
    email,
    telephone: '4003-2000',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Rio de Janeiro, Brazil',
      postalCode: '20081-902',
      streetAddress: 'Rua Sacadura Cabral, 130',
    },
    logo: {
      '@type': 'ImageObject',
      '@id': '#logo',
      url: ogImage,
      caption: shortName,
    },
    image: {
      '@id': '#logo',
    },
  };
};

export const fmtWebSiteSchema = ({ config }) => {
  const { domainUrl, shortName } = config;

  return {
    '@type': 'WebSite',
    '@id': '#website',
    name: shortName,
    url: domainUrl,
    publisher: {
      '@id': '#organization',
    },
    potentialAction: {
      '@type': 'SearchAction',
      target: `${domainUrl}/busca?conteudo={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  };
};

export const fmtWebPageSchema = ({ config, page, match, location }) => {
  const { domainUrl, appleStoreId, brand, androidId } = config;
  const term = getIn(match, ['params', 'term'], '');
  const pathname = getIn(location, ['pathname'], '');
  const { description, title } = page ? page : {};
  const targetPotentialAction = `${brand}/navigation${pathname}`;

  return {
    '@type': 'WebPage',
    '@id': `/busca/${term}/#webpage`,
    url: `${domainUrl}${pathname}`,
    inLanguage: 'pt-BR',
    name: title,
    isPartOf: {
      '@id': '#website',
    },
    primaryImageOfPage: {
      '@id': `${pathname}/#primaryimage`,
    },
    description: description,
    potentialAction: {
      '@type': 'ViewAction',
      target: [
        `ios-app://${appleStoreId}/${targetPotentialAction}`,
        `android-app://${androidId}/${targetPotentialAction}`,
      ],
    },
  };
};

export const fmtSearchResultsPageSchema = ({ page, location }) => {
  const pathname = getIn(location, ['pathname'], '');
  const { title } = page ? page : {};

  return {
    '@type': 'SearchResultsPage',
    '@id': `${pathname}/#search`,
    name: title,
    about: {
      '@id': `${pathname}/#itemList`,
    },
    mainEntityOfPage: {
      '@id': `${pathname}/#webpage`,
    },
  };
};

export const Schema = props => {
  const graph = [
    fmtOrganizationSchema(props),
    fmtWebSiteSchema(props),
    fmtWebPageSchema(props),
    fmtSearchResultsPageSchema(props),
    getSearchSchema(props),
  ];

  const schema = {
    '@context': 'https://schema.org',
    '@graph': graph,
    '@type': 'Schema',
  };
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />;
};

export default withConfig(Schema);
