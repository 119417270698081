import React from 'react';
import styled from 'styled-components';
import { Col } from '@catalogo/theme-grid';
import BlackboxPosition from '@catalogo/core-blackbox/position';
import ProductAdsCard from '@catalogo/ui-americanas-desktop-product-ads-card/src';
import ProductGrid from '@catalogo/ui-americanas-desktop-product-grid';
import { getTotalResultsText } from '@catalogo/ui-americanas-mobile-filter-menu/src/helpers';
import AutoCorrect from '../auto-correct';
import SearchSuggestions from '../search-suggestions';
import { BannerMercado } from '../banner-mercado';
import { Filters, SearchTop, SelectedFilter, SidebarAds, ToggleFilters } from '../sidebar-and-filters';

// eslint-disable-next-line max-lines-per-function
const SearchResult = props => {
  const {
    data,
    parsedQs = {},
    history,
    routeId,
    publications,
    pageType,
    isMarketEnabled,
    dispatchMetrics,
    sortByList,
    hasNextPage,
    variables,
    fetchMore,
    location,
  } = props;

  const total = data?.search?.total ?? '';
  const content = data?.search?.content ?? {};
  const defaultSearchSuggestions = data?.page?.defaultSearchSuggestions;
  const searchSuggestions = data?.page?.searchSuggestions ?? [];
  const products = data?.search?.products ?? [];
  const productsIds = data?.search?.productIds ?? [];
  const totalText = getTotalResultsText(total);

  return (
    <>
      <ColTop md={12}>
        {data.page && searchSuggestions && searchSuggestions.length > 0 && (
          <SearchSuggestions suggestions={searchSuggestions} />
        )}
        <BlackboxPosition
          routeId={routeId}
          position="contenttop1"
          publication={publications?.['contenttop1']}
          area="top"
        />
        {content.used && !content.suggestions ? (
          <Title>resultados para &ldquo;{content.term.replace(/-/g, ' ')}&rdquo;</Title>
        ) : (
          <AutoCorrect {...content} />
        )}
        <TotalText>{totalText}</TotalText>
        <ProductAdsCard
          publications={publications}
          pageType={pageType}
          positionAds="search_page.ads1"
          positionBrand="keyword.search.1"
        />
        {isMarketEnabled ? <BannerMercadoUI history={history} content={content} /> : null}
      </ColTop>
      <Col md={2}>
        <FilterTitle>filtrar por</FilterTitle>
        <ToggleFilters data={data} history={history} isMarketEnabled={isMarketEnabled} />
        <Filters
          defaultSearchSuggestions={defaultSearchSuggestions}
          data={data}
          parsedQs={parsedQs}
          history={history}
        />
        <SidebarAds routeId={routeId} publications={publications} />
      </Col>
      <ColUI md={10}>
        <SelectedFilter data={data} parsedQs={parsedQs} history={history} />
        <SearchTop parsedQs={parsedQs} history={history} />
        <ProductGrid
          products={products}
          productsIds={productsIds}
          fetchMore={fetchMore}
          parsedQs={parsedQs}
          variables={variables}
          hasNextPage={hasNextPage}
          history={history}
          total={total}
          dispatchMetrics={dispatchMetrics}
          location={location}
          sortByList={sortByList}
          {...props}
        />
        <CarouselWrapper>
          <BlackboxPosition
            routeId={routeId}
            position="search_page.ads2"
            publication={publications?.['search_page.ads2']}
          />
          <BlackboxPosition
            routeId={routeId}
            position="search_page.rr1"
            publication={publications?.['search_page.rr1']}
          />
        </CarouselWrapper>
      </ColUI>
    </>
  );
};

export default SearchResult;

const BannerMercadoUI = styled(BannerMercado)`
  margin: 0 0 16px 0;
`;

const Title = styled.h1`
  padding: 15px 0px;
  text-transform: lowercase;
  font-size: 28px;
  font-weight: bold;
  color: #666;
`;

const FilterTitle = styled.p`
  padding: 15px 0px;
  text-transform: lowercase;
  font-size: 20px;
  font-weight: bold;
  color: #666;
  border-bottom: solid 1px #ccc;
`;

const TotalText = styled.span`
  display: inline-block;
  margin-bottom: 24px;
  color: #666;
  font-size: 14px;
`;

const ColUI = styled(Col)`
  max-width: 1021px;
  padding-left: 28px;
`;

const ColTop = styled(Col)``;

const CarouselWrapper = styled.div`
  margin: 0 auto;
  max-width: 1000px;
`;
