import React from 'react';
import Breadcrumb from '@catalogo/theme-breadcrumb';
import { Col } from '@catalogo/theme-grid';
import BlackboxArea from '@catalogo/core-blackbox/area';
import * as Styled from './styles';
import { SellerAbout } from './components/seller-about';
import { IProps } from './types';

const TopArea: React.FC<IProps> = ({ breadcrumbData, routeId, publications, sellerAbout, history }) => (
  <Styled.Wrapper>
    <Styled.BreacrumbWrapper>
      <Breadcrumb breadcrumb={breadcrumbData?.slice(0)?.reverse()} />
    </Styled.BreacrumbWrapper>
    <Col md={12}>
      {sellerAbout?.hasSellerAbout && <SellerAbout history={history} {...sellerAbout} />}
      <BlackboxArea area="top" routeId={routeId} publications={publications} />
    </Col>
  </Styled.Wrapper>
);

export default TopArea;
