import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@catalogo/theme-skeleton';

export const Placeholder = () => (
  <Container>
    <Skeleton width="100%" height="196px" spinner />
  </Container>
);

export default Placeholder;

const Container = styled.div`
  width: 100%;
`;
