import React from 'react';
import styled, { css } from 'styled-components';

export const handleChange = (history, url, filtered) => () => {
  const event = new window.CustomEvent('filter:apply', {
    detail: {
      type: 'fast delivery',
      value: !filtered,
    },
  });
  window.document.dispatchEvent(event);
  window.location.href = url;
};

export const ToggleButton = ({ history, filtered, url }) => (
  <Wrapper>
    <Switch type="checkbox" onChange={handleChange(history, url, filtered)} />
    <Button toggle={filtered} />
  </Wrapper>
);

export default ToggleButton;

const Wrapper = styled.label`
  position: relative;
  width: 44px;
  height: 20px;
`;

const Switch = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Button = styled.span`
  position: absolute;
  cursor: pointer;
  height: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ toggle, theme }) => (toggle ? theme.green : theme.grey.medium)};
  transition: 0.4s;
  border-radius: 34px;

  &::before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: -4px;
    top: -1px;
    background-color: ${({ theme }) => theme.white};
    transition: 0.4s;
    border-radius: 50%;
    ${({ toggle }) =>
      toggle &&
      css`
        transform: translateX(26px);
      `}
  }
`;
