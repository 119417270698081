import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import { hasCriticalErrors } from '@catalogo/core-graphql-errors';
import Error from '@catalogo/template-shoptime-mobile-error';
import Schema from '@catalogo/template-americanas-mobile-search/src/schema/index';
import { withSearch } from '@catalogo/service-americanas-search';
import { withSortByOffer } from '@catalogo/service-americanas-search/src/hocs/sort-offer';
import HasNoProductsError from '@catalogo/ui-americanas-desktop-has-no-products-error';
import { Col, Grid } from '@catalogo/theme-grid';
import { withFastDeliveryExperiment } from '@catalogo/service-americanas-search/src/experiments/fast-delivery-experiment';
import Suggestions from '@catalogo/ui-americanas-desktop-suggestions';
import withNotFoundRedirect from '@catalogo/service-americanas-redirect/src/hoc/not-found';
import Loading from './components/loading';
import SearchResult from './components/search-result';
import { BlackboxMainBottomDefault, BlackboxMainBottomNoResult } from './components/custom-blackboxes';

export const Search = props => {
  const { data, error, loading, refetch, fetchMore } = props;
  const { parsedQs, history, location, publications } = props;
  const { term, routeId, variables, hasNextPage, sortByList, pageType, dispatchMetrics } = props;
  const { isMarketEnabled } = props;
  const hasNoProducts = !term || !loading;
  const products = data?.search?.productIds ?? [];
  const defaultSearchSuggestions = data?.page?.searchSuggestions;

  if (error && hasCriticalErrors(error.graphQLErrors, ['search'])) {
    return <Error retry={() => refetch()} />;
  }

  return (
    <Wrapper>
      {data && (
        <>
          <Schema {...props} {...data} />
          {loading && <Loading />}
          {Object.keys(data).length !== 0 ? (
            <GridUI>
              {products.length > 0 ? (
                <>
                  <SearchResult
                    data={data}
                    publications={publications}
                    parsedQs={parsedQs}
                    history={history}
                    routeId={routeId}
                    fetchMore={fetchMore}
                    variables={variables}
                    hasNextPage={hasNextPage}
                    dispatchMetrics={dispatchMetrics}
                    location={location}
                    sortByList={sortByList}
                    pageType={pageType}
                    isMarketEnabled={isMarketEnabled}
                  />
                  <Col md={12}>
                    <BlackboxMainBottomDefault routeId={routeId} publications={publications} />
                  </Col>
                </>
              ) : (
                <>
                  {hasNoProducts && <HasNoProductsError content={term?.replace(/-/g, ' ')} />}
                  <Col md={12}>
                    <BlackboxMainBottomNoResult routeId={routeId} publications={publications} />
                  </Col>
                </>
              )}
              <Col md={12}>
                <Suggestions defaultSearchSuggestions={defaultSearchSuggestions} />
              </Col>
            </GridUI>
          ) : (
            hasNoProducts && <HasNoProductsError content={term?.replace(/-/g, ' ')} />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default compose(withSortByOffer, withSearch, withFastDeliveryExperiment, withNotFoundRedirect)(Search);

const Wrapper = styled.main`
  background-color: #f1f1f1;
`;

const GridUI = styled(Grid)`
  padding: 0 12px;
  max-width: 1270px;
  margin: 0 auto;
`;
