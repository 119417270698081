import React from 'react';
import BlackboxPosition from '@catalogo/core-blackbox/position';
import { getIn } from 'seamless-immutable';

const AsideBlackboxPositions = ({ routeId, positions, publications }) =>
  positions.map(position => (
    <BlackboxPosition
      key={position}
      routeId={routeId}
      position={position}
      publication={getIn(publications, [{ position }])}
    />
  ));

export default AsideBlackboxPositions;
