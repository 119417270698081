import React from 'react';
import { withDevice } from '@catalogo/core-connect/device';
import { Breadcrumb, Maybe } from '@catalogo/core-types';
import { Arrow, Link, List, ListItem, Wrapper } from './styles';

interface BreadcrumbProps {
  breadcrumb?: Maybe<Breadcrumb[]>;
  textLowerCase?: string;
  device?: string | null;
  margin?: string;
}

export const Breadcrumbs: React.FC<BreadcrumbProps> = ({ breadcrumb, device, textLowerCase, margin }) => {
  if (!breadcrumb?.length) {
    return null;
  }

  const isMobile = device === 'mobile';
  const newBreadcrumb = [...breadcrumb, { name: 'Página Inicial', url: '/' }];

  return (
    <Wrapper isMobile={isMobile} margin={margin}>
      <List isMobile={isMobile}>
        {newBreadcrumb.reverse().map(({ name, url }, index) => (
          <ListItem key={url}>
            <Link isMobile={isMobile} href={url ? url : ''} textLowerCase={textLowerCase}>
              {name?.toLowerCase()}
            </Link>
            {newBreadcrumb.length - 1 !== index ? <Arrow /> : ''}
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

export default withDevice(Breadcrumbs);
