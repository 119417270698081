import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';

export const BannerMercado = ({ className, history, content }) => {
  const openModal = () => {
    const { pathname, search } = history.location;

    history.push({
      pathname,
      search,
      hash: '#modal-search-mercado-banner',
      state: {
        term: content?.term,
        redirectTo: `https://amercado.americanas.com.br/buscar/${content?.term}?acomSearch=true`,
      },
    });
  };

  const correct = () => (content?.used ? content?.used.term : content?.suggestions[0].term);

  return (
    <Container className={className}>
      <Inner>
        <Title>mercaaado</Title>
        <Subtitle>
          Encontre <strong>{correct()}</strong> nos melhores mercados e receba em casa ;)
        </Subtitle>
        <Button onClick={openModal}>ir para mercado</Button>
      </Inner>
      <Image
        src="https://images-americanas.b2w.io/spacey/acom/2022/02/09/banner-mercado-06f09a753587.png"
        alt="mercaaado"
      />
    </Container>
  );
};

export default BannerMercado;

const Container = styled.div`
  background-color: #fafafa;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  height: 196px;
  overflow: hidden;
`;

const Inner = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.span`
  display: block;
  color: #f80032;
  font-size: 62px;
  line-height: 48px;
  font-weight: bold;
  margin-bottom: 16px;
  text-indent: -3px;
`;

const Subtitle = styled.span`
  display: block;
  color: #000;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 24px;
`;

const Button = styled(Nav)`
  background: #f80032;
  color: #fff;
  border: none;
  height: 44px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 44px;
  align-self: flex-start;
  padding: 0 12px;
  transition: filter 0.3s;
  cursor: pointer;

  &:hover,
  &:active {
    filter: brightness(0.9);
  }
`;

const Image = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;
