import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { OutOfStockText } from '@catalogo/theme-product-card';
import ProductInfo from '../molecules/product-info';
import ImageInfo from '../molecules/image-info';

const OutOfStockSingleCard = ({ url, isSecondaryLayout, ...cardProps }) => (
  <Wrapper to={url}>
    <ContainerTop>
      <ImageInfo {...cardProps} />
      <ContainerInfo>
        <StyledProductInfo {...cardProps} />
        <OutOfStockWrapper $isSecondaryLayout={isSecondaryLayout}>
          <OutOfStockText />
        </OutOfStockWrapper>
      </ContainerInfo>
    </ContainerTop>
  </Wrapper>
);
export default OutOfStockSingleCard;

const Wrapper = styled(Nav)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 16px 0;
`;

const ContainerTop = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 16px 42px;
  border-left: 1px solid #e8e8e8;
  width: 100%;
`;

const ContainerInfo = styled.div`
  position: relative;
  padding: 20px 0;
  display: flex;
`;

const OutOfStockWrapper = styled.div`
  .outofstock-text {
    margin: 0;
    align-self: center;
    background: ${({ $isSecondaryLayout, theme }) => ($isSecondaryLayout ? theme.supportColors.light70 : 'none')};
    padding: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? '32px' : '0')};
    border-radius: 6px;

    &-content {
      display: block;
      font-size: 16px;
      line-height: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? '22px' : '18px')};
      width: 143px;
      text-align: center;
    }
  }
`;

const StyledProductInfo = styled(ProductInfo)`
  width: 520px;
`;
