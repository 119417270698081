import React from 'react';
import styled, { css } from 'styled-components';
import { ProductName, Rating } from '@catalogo/theme-product-card';

const ProductInfo = ({ rating, isSecondaryLayout, ...cardProps }) => (
  <Product $isSecondaryLayout={isSecondaryLayout}>
    <Sponsored>patrocinado</Sponsored>
    <ProductName {...cardProps} />
    <RatingWrapper>
      <Rating {...cardProps} rating={rating} size={16} />
    </RatingWrapper>
  </Product>
);

const Sponsored = styled.span`
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #666;
  margin-bottom: 12px;
`;

const RatingWrapper = styled.div`
  margin-top: 5px;
  line-height: 18px;
`;

const Product = styled.div`
  margin-right: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? '0' : '24px')};

  .product-name {
    ${({ theme }) => css`
      color: ${theme.grey.darker};
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      height: auto;
      max-height: 40px;
      text-transform: none;
    `}
  }
`;

export default ProductInfo;
