import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { OutOfStockText } from '@catalogo/theme-product-card';
import ProductInfo from '../molecules/product-info';
import ImageInfo from '../molecules/image-info';

const OutOfStockCardList = ({ url, isSecondaryLayout, ...cardProps }) => (
  <Wrapper to={url}>
    <ContainerTop>
      <ImageInfo {...cardProps} />
      <ContainerInfo $isSecondaryLayout={isSecondaryLayout}>
        <StyledProductInfo {...cardProps} />
        <OutOfStockWrapper $isSecondaryLayout={isSecondaryLayout}>
          <OutOfStockText />
        </OutOfStockWrapper>
      </ContainerInfo>
    </ContainerTop>
  </Wrapper>
);
export default OutOfStockCardList;

const Wrapper = styled(Nav)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 16px 0;
`;

const ContainerTop = styled.div`
  display: flex;
  position: relative;
  padding: 16px 42px;
  border-left: 1px solid #e8e8e8;
  width: 100%;
`;

const ContainerInfo = styled.div`
  position: relative;
  padding: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? '0' : '20px 0')};
  display: flex;
  flex-direction: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? 'column' : 'row')};
`;

const OutOfStockWrapper = styled.div`
  .outofstock-text {
    margin: 0;
    padding: 0;
    border-radius: 0;
    align-self: center;

    &-content {
      display: block;
      font-size: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? '14px' : '16px')};
      line-height: 18px;
      width: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? 'auto' : '143px')};
      text-align: center;
      margin-top: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? '10px' : '0')};
    }
  }
`;

const StyledProductInfo = styled(ProductInfo)`
  width: 223px;
`;
