import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import PriceInfo from '../molecules/price-info';
import ImageInfo from '../molecules/image-info';
import ProductInfo from '../molecules/product-info';

const InStockCardList = ({ url, handleClick, last = false, isSecondaryLayout, ...cardProps }) => (
  <Wrapper to={url} onClick={handleClick} last={last}>
    <ContainerTop>
      <ImageInfo {...cardProps} isSecondaryLayout={isSecondaryLayout} />
      <ContainerInfo $isSecondaryLayout={isSecondaryLayout}>
        <StyledProductInfo {...cardProps} $isSecondaryLayout={isSecondaryLayout} />
        <PriceInfo {...cardProps} isSecondaryLayout={isSecondaryLayout} />
      </ContainerInfo>
    </ContainerTop>
  </Wrapper>
);

export default InStockCardList;

const Wrapper = styled(Nav)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 16px 0;
`;

const ContainerTop = styled.div`
  display: flex;
  position: relative;
  padding: 16px 42px;
  border-left: 1px solid #e8e8e8;
  width: 100%;
`;

const ContainerInfo = styled.div`
  display: flex;
  position: relative;
  padding: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? '0' : '20px 0')};
  flex-wrap: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? 'wrap' : 'nowrap')};
`;

const StyledProductInfo = styled(ProductInfo)`
  width: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? '100%' : '223px')};
`;
