import React from 'react';
import { Nav } from '@catalogo/core-nav';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SearchSuggestions = ({ suggestions }) => {
  if (!suggestions) {
    return null;
  }
  return (
    <Container>
      <Title>buscas relacionadas</Title>
      {suggestions.map((suggestion, index) => {
        const last = index + 1 === suggestions.length;
        const qs = suggestion.href.indexOf('?') !== -1 ? '&' : '?';
        return (
          <Suggestion key={suggestion.term}>
            <SuggesttionLink to={`${suggestion.href}${qs}pfm_type=searchSuggestions`}>
              {suggestion.term}
            </SuggesttionLink>
            {!last && ','}
          </Suggestion>
        );
      })}
    </Container>
  );
};

SearchSuggestions.propTypes = {
  suggestions: PropTypes.array,
};

const Container = styled.div`
  display: flex;
  margin: 20px 0;
`;

const Title = styled.span`
  font-size: 16px;
  color: #666;
  font-weight: bold;
`;

const Suggestion = styled.div`
  margin-left: 6px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SuggesttionLink = styled(Nav)`
  font-size: 14px;
  color: #666;
  text-decoration: underline;
`;
export default SearchSuggestions;
