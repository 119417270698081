import React from 'react';
import styled from 'styled-components';
import ProductRating from '@catalogo/ui-americanas-desktop-rating';
import ProductName from '../atoms/product-name';

const ProductInfo = ({ rating, ...cardProps }) => (
  <Product>
    <Name {...cardProps} />
    <RatingWrapper>
      <Rating size={16} rating={rating} fontSize="12px" color="#999" />
    </RatingWrapper>
  </Product>
);

const Name = styled(ProductName)``;

const Rating = styled(ProductRating)`
  position: absolute;
  top: 47px;
  left: 0;
`;

const RatingWrapper = styled.div`
  margin-top: 5px;
  line-height: 20px;
`;

const Product = styled.div`
  width: 100%;
`;

export default ProductInfo;
