import { parse, stringify } from 'querystring';
import React from 'react';
import styled from 'styled-components';
import { Text } from '@catalogo/theme-text';
import { Nav } from '@catalogo/core-nav';
import PropTypes from 'prop-types';

const AutoCorrect = ({ term, link, used, suggestions }) => {
  if (!suggestions) {
    return null;
  }

  const getFormattedUrl = (url, suggestedTerm) => {
    const params = parse(url.replace('/search?', ''));

    params.suggestion = used;
    params.conteudo = term;
    return `/busca/${suggestedTerm}?${stringify(params)}`;
  };

  const renderSuggestion = (used, suggestion) => (
    <>
      <TextResult>exibindo resultado para: {used.term}</TextResult>
      <TextOriginal>
        em vez disso, pesquisar por:{' '}
        <TermLink to={getFormattedUrl(suggestion.link, suggestion.term)}>{suggestion.term}</TermLink>
      </TextOriginal>
    </>
  );

  return (
    <WrapperUI>
      {used && renderSuggestion({ used, link, term }, suggestions[0])}
      {!used && renderSuggestion(suggestions[0], { used, link, term })}
    </WrapperUI>
  );
};

AutoCorrect.propTypes = {
  term: PropTypes.string,
  link: PropTypes.string,
  used: PropTypes.bool,
  suggestions: PropTypes.array,
};

const WrapperUI = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 15px 12px;
`;

const TextResult = styled(Text)`
  white-space: nowrap;
  color: #666;
  font-size: 22px;
  font-weight: bold;
`;

const TextOriginal = styled(TextResult)`
  font-size: 16px;
  margin-top: 11px;
`;

const TermLink = styled(Nav)`
  color: #666;
  text-decoration: underline;
`;

export default AutoCorrect;
