import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import { hasCriticalErrors } from '@catalogo/core-graphql-errors';
import Error from '@catalogo/template-americanas-mobile-error';
import { withBrand } from '@catalogo/service-americanas-brand';
import Schema from '@catalogo/template-americanas-mobile-search/src/schema';
import { getIn } from 'seamless-immutable';
import { Col } from '@catalogo/theme-grid';
import withNotFoundRedirect from '@catalogo/service-americanas-redirect/src/hoc/not-found';
import Loading from './components/loading';
import TopArea from './components/top-area';
import MiddleArea from './components/middle-area';
import { fmtSearchSuggestions } from './helpers';

export const Brand = props => {
  const {
    data,
    error,
    loading,
    refetch,
    routeId,
    publications,
    parsedQs = {},
    history,
    fetchMore,
    dispatchMetrics,
    isToRenderList,
  } = props;

  const defaultSearchSuggestions = fmtSearchSuggestions(getIn(data, ['page', 'defaultSearchSuggestions'], []));

  const { selectedAggregations, aggregations } = data?.search || {
    selectedAggregations: [],
    aggregations: [],
  };

  const cleanAll = getIn(data, ['search', 'cleanAll'], '/');
  const products = getIn(data, ['search', 'products'], []);

  if (error && hasCriticalErrors(error.graphQLErrors, ['brand'])) {
    return <Error retry={() => refetch()} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      {data && Object.keys(data).length > 0 ? (
        <>
          <Schema {...props} {...data} />
          <GridUI>
            <Col md={12}>
              <TopArea
                data={data}
                routeId={routeId}
                publications={publications}
                isToRenderList={isToRenderList}
                products={products}
              />
            </Col>
            <MiddleArea
              routeId={routeId}
              publications={publications}
              positions={['sidebartop1', 'sidebartop2']}
              defaultSearchSuggestions={defaultSearchSuggestions}
              aggregations={aggregations}
              history={history}
              parsedQs={parsedQs}
              selectedAggregations={selectedAggregations}
              cleanAll={cleanAll}
              data={data}
              products={products}
              dispatchMetrics={dispatchMetrics}
              fetchMore={fetchMore}
              isToRenderList={isToRenderList}
            />
          </GridUI>
        </>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: ${({ theme }) => theme.white};
`;

const GridUI = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.white};
  max-width: 1274px;
  padding: 0 12px;
  margin: 0 auto;
`;

export default compose(withBrand, withNotFoundRedirect)(Brand);
