import { ParsedUrlQuery } from 'querystring';
import React from 'react';
import { hasCriticalErrors } from '@catalogo/core-graphql-errors';
import Error from '@catalogo/template-americanas-mobile-error';
import { withSeller } from '@catalogo/service-americanas-seller';
import Schema from '@catalogo/template-americanas-mobile-search/src/schema';
import { Col } from '@catalogo/theme-grid';
import Loading from './components/loading';
import TopArea from './components/top-area';
import MiddleArea from './components/middle-area';
import { TProps } from './types';
import * as Styled from './styles';
import { handleRefetch } from './helpers';

export const Seller: React.FC<TProps> = props => {
  const {
    data,
    error,
    loading,
    refetch,
    routeId,
    publications,
    products,
    parsedQs = {},
    history,
    fetchMore,
    dispatchMetrics,
    sellerAbout,
    breadcrumbData,
    isSpecialSeller,
    isSpecialOrGridContent,
    sortByList,
  } = props;

  const graphQLErrors = error?.graphQLErrors as Array<unknown>;

  if (error && hasCriticalErrors(graphQLErrors, ['category'])) {
    return <Error retry={handleRefetch(refetch)} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {data && Object.keys(data).length > 0 && (
        <Styled.Container isSpecialSeller={isSpecialSeller}>
          <Schema {...props} {...data} />
          <Styled.Wrapper>
            <Col md={12}>
              <TopArea
                history={history}
                breadcrumbData={breadcrumbData}
                routeId={routeId}
                publications={publications}
                sellerAbout={sellerAbout}
                isSpecialOrGridContent={isSpecialOrGridContent}
              />
            </Col>
            <Col md={12}>
              <MiddleArea
                routeId={routeId}
                publications={publications}
                history={history}
                parsedQs={parsedQs as ParsedUrlQuery}
                data={data}
                products={products}
                dispatchMetrics={dispatchMetrics}
                fetchMore={fetchMore}
                isSpecialSeller={isSpecialSeller}
                isSpecialOrGridContent={isSpecialOrGridContent}
                sortByList={sortByList}
              />
            </Col>
          </Styled.Wrapper>
        </Styled.Container>
      )}
    </>
  );
};
export default withSeller(Seller);
