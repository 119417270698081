import React from 'react';
import { getSortByOffer } from '../helpers/helpers';
import { WithSortByOfferProps } from '../types';

export const withSortByOffer = (WrappedComponent: React.FC) => {
  const EnhancedSearch = (props: WithSortByOfferProps) => {
    const { sortBy } = props.parsedQs;
    const componentProps = {
      ...props,
      sortByOffer: getSortByOffer(sortBy),
    };

    return <WrappedComponent {...componentProps} />;
  };
  return EnhancedSearch;
};
