import React from 'react';
import BlackboxPosition from '@catalogo/core-blackbox/position';
import styled from 'styled-components';

export const BlackboxMainBottomDefault = ({ routeId, publications }) => (
  <>
    <BlackboxPosition routeId={routeId} position="mainbottom1" publication={publications?.mainbottom1} />
    <BlackboxPosition
      routeId={routeId}
      position="search_page.history"
      publication={publications?.['search_page.history']}
    />
    <BlackboxPosition routeId={routeId} position="mainbottom2" publication={publications?.mainbottom2} />
    <BlackboxPosition routeId={routeId} position="mainbottom3" publication={publications?.mainbottom3} />
    <BottomBanner>
      <BlackboxPosition routeId={routeId} position="contentbottom1" publication={publications?.contentbottom1} />
    </BottomBanner>
    <BlackboxPosition routeId={routeId} position="schema" publication={publications?.schema} />
  </>
);

export const BlackboxMainBottomNoResult = ({ routeId, publications }) => (
  <>
    <BlackboxPosition
      routeId={routeId}
      position="search_page.rr1_noresults"
      publication={publications?.['search_page?.rr1_noresults']}
    />
    <BlackboxPosition
      routeId={routeId}
      position="search_page.rr2_noresults"
      publication={publications?.['search_page?.rr2_noresults']}
    />
    <BlackboxPosition routeId={routeId} position="search.2" publication={publications?.['search.2']} />
    <BottomBanner>
      <BlackboxPosition routeId={routeId} position="contentbottom1" publication={publications?.contentbottom1} />
      <BlackboxPosition routeId={routeId} position="schema" publication={publications?.schema} />
    </BottomBanner>
  </>
);

const BottomBanner = styled.div`
  padding: 18px 0 22px;
`;
