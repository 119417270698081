import React from 'react';
import styled from 'styled-components';
import withProductAds from '@catalogo/service-americanas-product-card/src/hocs/product-ads';
import InStockCardList from './components/organisms/inStockCardList';
import OutOfStockCardList from './components/organisms/outOfStockCardList';
import CardBrandList from './components/organisms/cardBrandList';
import { Placeholder } from './skeleton';

export const ProductAds = props => {
  const { loading, called, brand, outOfStock } = props;

  if (loading || !called) {
    return <Placeholder />;
  }

  return (
    <Container>
      {!brand && !outOfStock && <InStockCardList {...props} />}
      {brand && <CardBrandList {...props} />}
      {outOfStock && <OutOfStockCardList {...props} />}
    </Container>
  );
};

export default withProductAds(ProductAds);

const Container = styled.div`
  height: 196px;
  flex: 1 1 100%;
`;
