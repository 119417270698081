import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
  margin-bottom: -34px;
`;

export const Seal = styled.span`
  ${({ theme }) => css`
    position: relative;
    width: 85px;
    background-color: ${theme.complementaryColors.blue.light40};
    padding: 3.5px 16px;
    font-size: ${theme.fontSize.xxxs};
    font-weight: ${theme.typography.fontWeight.bold};
    color: ${theme.complementaryColors.blue.dark40};

    &:before {
      content: '';
      position: absolute;
      border: 9px solid ${theme.complementaryColors.blue.light40};
      border-left-color: transparent;
      top: 0;
      left: -8px;
    }
  `}
`;
