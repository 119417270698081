import React from 'react';
import styled, { css } from 'styled-components';
import { Text } from '@catalogo/theme-text';

const ProductName = ({ name, className }) => (
  <Name className={className} numberOfLines={2}>
    {name}
  </Name>
);

const Name = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    max-height: 32px;
  `}
`;

export default ProductName;
