import React from 'react';
import styled from 'styled-components';
import { CardImage } from '@catalogo/theme-product-card';

const ImageInfo = cardProps => (
  <Wrapper $isSecondaryLayout={cardProps.isSecondaryLayout}>
    <CardImage {...cardProps} backgroundColor="transparent" errorPlaceholderSize="130px" />
  </Wrapper>
);

export default ImageInfo;

const Wrapper = styled.div`
  margin-right: 16px;
  max-width: ${({ $isSecondaryLayout }) => ($isSecondaryLayout ? '130px' : '')};

  .card-image {
    display: block;
    padding: 0;
    margin: 0;
    width: 130px;
    max-width: 130px;
    height: 130px;

    img {
      mix-blend-mode: normal;
    }
  }
`;
