/* eslint-disable max-statements */
import React from 'react';
import { getIn } from 'seamless-immutable';
import compose from 'lodash/fp/compose';

import { withMetrics } from '@catalogo/core-metrics';
import { withCookies } from '@catalogo/core-connect/cookies';
import { withNavigation } from '@catalogo/core-connect/navigation';

import { useHeaderContext } from '@catalogo/service-americanas-header/src/context/header';
import withNotFoundRedirect from '@catalogo/service-americanas-redirect/src/hoc/not-found';
import { useHeaderInputLojista } from '../hooks/seller';
import { useGetSellerData } from '../hooks/seller';
import { IWithSellerProductProps, IInjectedProps } from '../types/hocs/seller-products';
import { hasSellerAbout } from '../helpers/seller-products';
import { checkIsContextBrand } from '../helpers/context';

export const withSellerProducts = (Component: React.FC<IWithSellerProductProps & IInjectedProps>) => (
  props: IWithSellerProductProps
) => {
  const { history, location, routeId, parsedQs = {}, match, dispatchMetrics, sortByList, turboData } = props;
  const pathname = getIn(location, ['pathname'], '');
  const sitepage = getIn(parsedQs, ['sitepage']) ? `/${getIn(parsedQs, ['sitepage'])}` : undefined;
  const sellerId = turboData?.id;
  const content = decodeURIComponent(parsedQs?.conteudo || '')
    .replace(/\+/g, ' ')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-');

  const { loading, sellerData, refetch, error, fetchMore, networkStatus } = useGetSellerData({
    content: content,
    pathname: pathname,
    parsedQs,
    dispatchMetrics,
    routeId,
    sitepage,
    sellerId,
  });

  const { context } = useHeaderContext();
  const sellerName = getIn(match, ['params', 'seller'], '');
  const isContextBrand = checkIsContextBrand(context);
  const newContext = isContextBrand ? context : sellerName;
  const name = sellerData?.page?.name?.toLowerCase();
  const placeholder = isContextBrand ? 'busque aqui seu produto' : `busque aqui, produtos exclusivos ${name}`;

  useHeaderInputLojista({
    slug: sellerName,
    content: 'lojista',
    context: newContext,
    name: sellerData?.page?.name,
    placeholder: placeholder ?? '',
  });

  const products = getIn(sellerData, ['search', 'products'], []);
  const breadcrumbData = getIn(sellerData, ['page', 'breadcrumb'], []);
  const sellerAbout = sellerData?.seller;

  const injectedProps = {
    data: sellerData,
    error,
    products,
    breadcrumbData,
    history,
    loading,
    parsedQs,
    refetch,
    routeId,
    term: context,
    fetchMore,
    networkStatus,
    dispatchMetrics,
    sortByList,
    sellerAbout: { ...sellerAbout, hasSellerAbout: hasSellerAbout(sellerAbout, products) },
  };

  return <Component {...injectedProps} {...props} />;
};

export default compose(withCookies, withMetrics, withNavigation, withSellerProducts, withNotFoundRedirect);
