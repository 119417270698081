import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import ProductInfo from '../molecules/product-info';
import ImageInfo from '../molecules/image-info';

const OutOfStockCardList = ({ url, ...cardProps }) => (
  <Wrapper to={url}>
    <ContainerTop>
      <ImageWrapper>
        <ImageInfo {...cardProps} />
      </ImageWrapper>
      <ContainerInfo>
        <ProductInfo {...cardProps} />
        <OutOfStockWrapper>
          <OutOfStockText>Ops! Já vendemos todo o estoque.</OutOfStockText>
        </OutOfStockWrapper>
      </ContainerInfo>
    </ContainerTop>
  </Wrapper>
);
export default OutOfStockCardList;

const Wrapper = styled(Nav)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 16px 0;
`;

const ContainerTop = styled.div`
  display: flex;
  position: relative;
  padding: 16px 32px;
  border-left: 1px solid #e8e8e8;
`;

const ImageWrapper = styled.div`
  margin-right: 16px;
`;

const ContainerInfo = styled.div`
  position: relative;
  padding: 7px 0;
`;

const OutOfStockWrapper = styled.div`
  color: ${({ theme }) => theme.grey.dark};
  white-space: normal;
  align-self: center;
  margin-top: 16px;
`;

const OutOfStockText = styled.span`
  display: block;
  font-size: 16px;
  line-height: 18px;
`;
