import React from 'react';
import styled, { css } from 'styled-components';
import ProductName from '../atoms/brand-name';

const ProductInfoBrand = ({ headline, ...cardProps }) => (
  <Product>
    <Name {...cardProps} />
    <HeadLine>{headline}</HeadLine>
  </Product>
);

const Name = styled(ProductName)`
  margin-bottom: 34px;
`;

const Product = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 7px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

const HeadLine = styled.p`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: 12px;
    text-transform: lowercase;
    font-weight: bold;
    line-height: 15px;
  `}
`;

export default ProductInfoBrand;
