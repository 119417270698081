import { useQuery } from '@apollo/client';
import { getIn } from 'seamless-immutable';
import { getPlacements } from '@catalogo/service-americanas-brand/src/helpers/template';
import { formatFilters } from '@catalogo/core-filters/src/helpers';
import { searchBrand } from '../queries/brand.graphql';
import { getDeferedBrand } from '../queries/defered.graphql';
import { UseGetBrandDataProps, UseGetBrandDeferedDataProps } from '../types';

export const useGetBrandData = ({
  dispatchMetrics,
  parsedQs,
  pathname,
  routeId,
  sitepage,
  qs,
}: UseGetBrandDataProps) => {
  const { sortBy = 'relevance', source = 'blancamarca', limit = 24, offset = 0 } = parsedQs;
  const variables = {
    path: pathname,
    filters: formatFilters(qs),
    sortBy,
    source,
    limit: typeof limit === 'number' ? limit : parseInt(limit),
    offset: typeof offset === 'number' ? offset : parseInt(offset),
    routeId,
    sitepage,
  };

  const { loading, data = {}, refetch, error, previousData = {}, fetchMore, networkStatus } = useQuery(searchBrand, {
    variables,
    notifyOnNetworkStatusChange: true,
    onCompleted: /* istanbul ignore next */ data => {
      dispatchMetrics('page:load', data);
    },
  });

  const brandData = loading ? previousData : data;

  return { loading, brandData, refetch, error, fetchMore, networkStatus };
};

export const useGetBrandDeferedData = (props: UseGetBrandDeferedDataProps) => {
  const { dispatchMetrics, mergeComponents, page = {} } = props;

  const template = getIn(page, ['template'], {});

  const recPlacements = getPlacements('recommendation', template);

  const b2wAdsPlacements = getPlacements('b2wads', template);
  const brandId = getIn(page, ['breadcrumb', 0, 'id']);

  const { name } = page;

  const variables = {
    brandId,
    recPlacements,
    b2wAdsPlacements,
    term: name ? name.toLowerCase() : '',
    pageType: 'marca',
  };

  const { data: dataDefered } = useQuery(getDeferedBrand, {
    variables,
    skip: recPlacements.length < 1 && b2wAdsPlacements.length < 1,
    ssr: false,
    onCompleted: /* istanbul ignore next */ data => {
      dispatchMetrics('recommendation:load', mergeComponents(page, data));
    },
  });

  return dataDefered;
};
