import { PublicationsType } from '../types';

export const useFeatureProduct = (
  publications: PublicationsType,
  { positionAds, positionBrand }: { positionAds: number; positionBrand: number }
) => {
  const featuredProducts = publications?.[positionAds];
  const productsWithBrand = publications?.[positionBrand];
  const products = productsWithBrand ? productsWithBrand?.products : featuredProducts?.products;

  if (!featuredProducts && !productsWithBrand && !products?.length) {
    return {
      products: [],
      sponsorName: null,
      position: null,
      brand: null,
      publication: null,
      error: true,
    };
  }

  if (productsWithBrand) {
    return {
      products: products,
      sponsorName: productsWithBrand?.sponsorName || null,
      position: positionBrand,
      brand: true,
      publication: productsWithBrand,
      error: false,
    };
  }

  return {
    products: products,
    sponsorName: null,
    position: positionAds,
    brand: false,
    publication: featuredProducts,
    error: false,
  };
};
