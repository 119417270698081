import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 1px ${theme.supportColors.light80} solid;
    border-radius: 8px;
    margin-right: 16px;
  `}
`;

export const SellerImg = styled.img`
  ${({ theme }) => css`
    width: 150px;
    height: 150px;
    object-fit: contain;
    border: 1px ${theme.supportColors.light80} solid;
    border-radius: 8px;
  `}
`;
