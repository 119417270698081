import styled from 'styled-components';

export const SearchTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  border-top: solid 2px ${({ theme }) => theme.supportColors.light50};
  margin-top: 20px;
`;

export const TotalText = styled.span`
  color: ${({ theme }) => theme.textColor};
  font-size: ${({ theme }) => theme.fontSize.xs};
`;
