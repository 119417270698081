import React from 'react';
import { getIn } from 'seamless-immutable';
import SortBy from '@catalogo/ui-americanas-desktop-sort-by';
import ProductGrid from '@catalogo/ui-americanas-desktop-product-grid';
import { getTotalResultsText } from '@catalogo/ui-americanas-mobile-filter-menu/src/helpers';
import { TProps } from './types';
import * as Styled from './styles';

export const GridArea: React.FC<TProps> = ({
  data,
  parsedQs,
  history,
  products,
  fetchMore,
  dispatchMetrics,
  sortByList,
}) => {
  const total = getIn(data, ['search', 'total']);
  const productsIds = getIn(data, ['search', 'productIds'], []);
  const hasNextPage = getIn(data, ['search', 'hasNextPage'], false);

  const term = getIn(data, ['search', 'term'], false);
  const totalText = getTotalResultsText(total);

  return (
    <>
      <Styled.SearchTop>
        <Styled.TotalText>{totalText}</Styled.TotalText>
        <SortBy history={history} parsedQs={parsedQs} />
      </Styled.SearchTop>
      <ProductGrid
        products={products}
        productsIds={productsIds}
        hasNextPage={hasNextPage}
        dispatchMetrics={dispatchMetrics}
        fetchMore={fetchMore}
        term={term}
        total={total}
        history={history}
        parsedQs={parsedQs}
        sortByList={sortByList}
      />
    </>
  );
};

export default GridArea;
