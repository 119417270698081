import React from 'react';
import styled, { css } from 'styled-components';
import { Text } from '@catalogo/theme-text';

export const SalesPrice = ({ price }) => <Price>{price}</Price>;

export const ListPrice = ({ className, promotionalPrice }) => (
  <PromotionalPrice className={className}>{promotionalPrice}</PromotionalPrice>
);

const Price = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.grey.dark};
    font-size: 12px;
    text-decoration: line-through;
    font-weight: 400;
    line-height: 14px;
  `}
`;

const PromotionalPrice = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
  `}
`;

export default SalesPrice;
